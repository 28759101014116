import { Grid, Typography, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import { Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation, HandshakeAnimation, AboutUsAnimation } from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { Caner, Erdem, Hilal, Percihan, Yigit } from 'assets';
import parse from 'html-react-parser';

const html = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Üst Bilgi Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Alt Bilgi Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.stBilgiChar
	{mso-style-name:"Üst Bilgi Char";
	mso-style-link:"Üst Bilgi";}
span.AltBilgiChar
	{mso-style-name:"Alt Bilgi Char";
	mso-style-link:"Alt Bilgi";}
.MsoChpDefault
	{font-size:11.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:42.55pt 42.55pt 42.55pt 42.55pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=TR link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNoSpacing>&nbsp;</p>

<p class=MsoNoSpacing align=center style='text-align:center;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KVKK AYDINLATMA METNİ </span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bu metin, 6698 sayılı Kişisel Verilerin
Korunması Kanunu (KVKK) kapsamında SINIRLI SORUMLU ÖZGELECEK İNSAN KAYNAKLARI
TEKNOLOJİLERİ A.Ş.’nin (Metnin devamında ÖZGELECEK A.Ş. olarak anılacaktır.) KVKK’ya
uyumluluğunun sağlanmasını ve SINIRLI SORUMLU ÖZGELECEK A.Ş. tarafından kişisel
verilerin korunması ve işlenmesine ilişkin yükümlülüklerin yerine
getirilmesinde uyulacak ilkelerin belirlenmesini amaçlamaktadır.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bu kapsamda Veri Sorumlusu sıfatıyla,
kişisel bilgileriniz aşağıda açıklandığı çerçevede, farklı kanallar ve hukuki
gerekçeler çerçevesinde temin edilmekte; kaydetme, saklama gibi süreçler
çerçevesinde işlenmekte; mevzuatın izin verdiği durumlarda 3.kişilere
devredilmekte, söz konusu verilerin güvenliği ve yetkisiz kişilerin erişiminin
engellenmesi konusunda her türlü idari ve teknik tedbirler alınmaktadır.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş. olarak,
kişisel verilerinizin KVKK’ya uygun olarak işlenerek, muhafaza edilmesine büyük
önem atfetmekteyiz. Bu sorumluluğumuzun tam idraki ile KVKK’da tanımlı şekli
ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi aşağıda izah edildiği
surette ve mevzuat tarafından belirlenen sınırlar çerçevesinde işlemekteyiz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>İNTERNET SİTEMİZ DOLAYISIYLA İŞLEMEKTE
OLDUĞUMUZ KİŞİSEL VERİLER</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş.; şirketin
internet sitesine üye olan Kişilere ait, kanunun kişisel veri olarak
belirlediği ad-soyad, telefon numarası, adres bilgisi ve e-posta adresi
bilgilerini hukuka uygun olarak saklamakta ve işlemektedir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>İşlenen Kişisel Veriler:</span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Veri Kategorisi</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kişisel Veri</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Site Kullanımına İlişkin Veriler</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Size özel tahsis edilmiş Kullanıcı Adı
  ve Şifreniz, Sitedeki davranışlarınız, işlemleriniz, tercihleriniz, ziyaret
  edilen ürünler</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Diğer</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Tarafımıza iletmiş olduğunuz
  mesajın içeriğinde yer alabilecek diğer kişisel veriler</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Üyelik ve Satış Sözleşmesinin Kurulması
ile Beraber İşlenen Veriler: </span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Veri Kategorisi</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kişisel Veri</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Fatura Bilgisi (Fatura Talep Edildiği
  Takdirde)</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Tc Kimlik No, Vergi Kimlik No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Ödeme Bilgisi (Havale Yoluyla)</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Banka Iban ve Kullandığınıza
  Bankaya ilişkin bilgiler</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kullanıcı Olmanız Halinde İşlenen
Veriler: &nbsp;</span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Veri Kategorisi</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kişisel Veri</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Site Kullanımına İlişkin Veriler</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Size özel tahsis edilmiş
  kullanıcı adı ve şifreniz</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Talep Ve Şikayet Yönetimi</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Şirketimiz tarafından verilen
  hizmetlerin kayıtları</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kullanıcı olmanız halinde işlenen
verilerinizin tutulmasındaki amaç, size daha iyi bir hizmet sunabilmektir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Elektronik Ticari İletişim İzni Vermeniz
Durumunda: </span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Site Kullanımına İlişkin Veriler</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Sitedeki davranışlarınız,
  işlemleriniz, tercihleriniz, ziyaret edilen ürünler</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 style='width:230.3pt;border:solid black 1.0pt;border-top:none;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Pazarlamaya Esas Bilgileriniz</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Doğum tarihi, Site kullanımları,
  alışveriş alışkanlıkları, , adres, ve alışkanlıklar gibi demografik
  üye/kullanıcı/müşteri bilgileri, , her türlü ürüne ve hizmete ilişkin
  alışveriş alışkanlığı-tercihler, beğeniler ve ilgili yorumlar, yararlanılan
  kampanya, anket vb.leri ile içerikleri, fatura içerikleri, ödeme
  yöntemleri(nakit, kredi kartı vs.) ve ödeme detayları (taksit miktarı vs.),
  eski ve yeni cep/ev/iş telefon/faks numaraları, mail adresleri, tanımlama
  bilgileri (çerez, web tarayıcı işaretleri-bilgisi, IP, beacon,
  kablolu-kablosuz ağ bağlantı bilgisi</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİLERİN TOPLANMASI, İŞLENMESİ
VE İŞLEME AMAÇLARI</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Üye, ziyaretçi ve kullanıcıların verileri
SINIRLI SORUMLU ÖZGELECEK A.Ş. tarafından uygulanan faaliyetlerin ilgili
mevzuat ve uygulamalara uygun olarak yerine getirilmesini sağlamak için
aşağıdaki amaçlarla KVKK’nın 5. ve 6. maddelerinde belirtilen kapsamda
işlenecektir:</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>GENEL AMAÇLAR:</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Bizimle iletişime geçtiğiniz takdirde
sorun ve şikayetlerinizi çözümlemek ve gerektiğinde buna ilişkin olarak sizinle
iletişime geçebilmek amacıyla kimlik ve iletişim bilgilerinizi, </span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Mevzuattan kaynaklanan
yükümlülüklerimizi yerine getirmek, yetkili ve görevli kamu kurum ve
kuruluşları ile bilgi güvenliği başta olmak üzere diğer hukuki yükümlüklerimizi
yerine getirmek amaçlarıyla kimlik, iletişim, fatura, alışveriş bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Sözleşmeden doğacak uyuşmazlıklarda
mahkeme, icra dairesi, hakem heyeti gibi resmi kurum ve kuruluşlara karşı her
türlü dava, cevap ve itiraz hakkının kullanılması amacıyla kimlik, iletişim,
fatura, alışveriş bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Size ilişkin sistemimizde
oluşturduğumuz kayıtların diğer üyelere ilişkin kayıtlardan ayırt edilebilmesi,
fatura/fiş ibraz etmeksizin satış sonrası operasyonel süreçlerden
faydalanabilmenizi sağlamak amacıyla kimlik ve iletişim bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Fatura düzenlenmesini talep etmeniz
halinde fatura düzenlenebilmesi amacıyla kimlik ve fatura bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Ödemenizi kredi kartı ile yapmak
istemeniz halinde ödemenin alınabilmesi amacıyla kredi kartı bilgileriniz
(kredi kartı bilgileri kaydedilmeden ödeme kuruluşuna aktarılmaktadır.),</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Mevzuattan kaynaklanan saklama
yükümlülüklerimizi yerine getirmek amacıyla kimlik, alışveriş ve fatura
bilgilerinizi.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>ELEKTRONİK TİCARİ İLETİŞİM İZNİ VERMENİZ
HALİNDE:</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Genel veya size özel kişiselleştirilmiş
kampanyaların, avantajların, promosyonların, reklamların, bilgilendirmelerin,
pazarlama faaliyetlerinin oluşturulabilmesi/yapılabilmesi, size yönelik her
türlü ticari iletişim faaliyetlerinde bulunulabilmesi amacıyla kimlik,
iletişim, alışveriş ve pazarlamaya esas bilgilerinizi işliyoruz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ
VE HUKUKİ SEBEBİ</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kişisel verileriniz SINIRLI SORUMLU ÖZGELECEK
A.Ş. tarafından; Site üzerinden işlemleriniz, talep ve şikayetlerinize ilişkin
veya üyeliğiniz ile beraber, Site üzerindeki her türlü alışveriş, tahsilat,
işlem, anket doldurma, otomatik yöntemlerle veya elektronik sistemler vasıtası
ile alınan Elektronik Ticari İletişim İzniniz çerçevesinde işlenmektedir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş.olarak
kişisel verilerinizi; Site üzerindeki ozgelecek.net  süreçlerine ilişkin olarak
“sözleşmenin kurulması ve ifası için veri işlemenin gerekli olması”, “hukuki
yükümlülüğün yerine getirilmesi” ile “veri sorumlusunun meşru menfaatleri için
veri işlenmesinin zorunlu olması hukuki sebeplerine; mevzuattan kaynaklanan
saklama yükümlülüklerimize ilişkin “hukuki yükümlülüğün yerine getirilmesi”
hukuki sebebine; üye olmanız halinde ”sözleşmenin kurulması ve ifası için veri
işlemenin gerekli olması” talep ve şikayetlerinizin takibi açısında “Hakkın
kullanılması için veri işlemenin zorunlu olması” sebebine dayanarak, kişisel
verileriniz Elektronik Ticari İletişim İzni vermeniz durumunda ve Verilerinizin
İş Ortakları ile size yeni hizmetler sunulması amacı ile açık rıza hukuki
sebeplerine dayanarak topluyoruz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Açık rızanın bulunması; şirketimizin tabi
olduğu sair mevzuatta açıkça öngörülmüş olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bir sözleşmenin kurulması veya ifasıyla
doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
verilerin işlenmesinin gerekli olması, talep edilen ürün ve hizmetleri
sunabilmek ve akdettiğiniz sözleşmelerinin gereğinin yerine getirilmesi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Hukuki yükümlülüğün yerine getirebilmesi
için zorunlu olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>İlgili kişinin kendisi tarafından
alenileştirilmiş olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bir hakkın tesisi, kullanılması veya
korunması için veri işlemenin zorunlu olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>İlgili kişinin temel hak ve
özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
için veri işlenmesinin zorunlu olması.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Açık rızanızın bulunması; Sağlık
dışındaki kişisel veriler, kanunlarda öngörülen hallerde açık rızası
aranmaksızın,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Sağlığa ilişkin kişisel veriler ise
ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve
bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının
planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan
kişiler veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası
aranmaksızın.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİLERİNİZİN AKTARILMASI</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş. olarak
KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve
amaçları çerçevesinde kişisel verilerinizi;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bilişim teknolojileri, pazarlama
faaliyetleri ya da uzmanlık gerektiren danışmanlık vb. hizmetlerini almak,
kişisel veri toplama süreçlerinde ürün ve hizmet desteği almak gibi amaçlarla
yurtiçi ve yurtdışında bulunan iş ortakları ve hizmet sağlayıcılarıyla (çağrı
merkezi, cihazlar aracılığıyla kişisel veri toplama hizmeti, pazarlama
danışmanlığı, veri tabanı, elektronik ileti aracı hizmet sağlayıcıları,
danışmanlık, vb. hizmeti veren firmaları ile) veya yurtiçindeki iş
ortaklarımızın sunucularının yurtdışında olması halinde yurtdışı ile,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Site üzerinden yaptığınız
alışverişlerinizin ve diğer her türlü taleplerinizin çözümü amacı ile iş
ortaklarımızla,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Yetkili ve görevli kamu kurum ve
kuruluşları ile adli makamlara karşı olan bilgi, belge verme ve ilgili sair
yükümlülüklerimizi yerine getirmek ve dava ve cevap hakları gibi yasal
haklarımızı kullanabilmek amacıyla bizden istenen bilgileri anılan bu kurum,
kuruluş ve makamlara,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Ödemenizi kredi kartı ile yapmanız
halinde kredi kartı bilgilerinizi,&nbsp;PayTR&nbsp;tarafından kaydedilmeksizin
ilgili banka, elektronik ödeme kuruluşu vb. hizmeti sağlayan üçüncü kişilere
aktarmaktayız.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>İLGİLİ KİŞİ OLARAK KVKK’NIN 11.
MADDESİNDE SAYILAN HAKLARINIZ</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>6698 sayılı Kişisel Verilerin Korunması
Kanunu’nun 11. maddesinde düzenlenen haklar kapsamında kişisel veri sahipleri;</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerinizin korunmasına
yönelik haklarınız hakkında ayrıntılı bilgi alabilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel veri işlenip işlenmediğini
öğrenebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verileri işlenmişse buna ilişkin
bilgi talep edebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerin işlenme amacını ve
bunların amacına uygun kullanılıp kullanılmadığını öğrenebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Yurt içinde veya yurt dışında kişisel
verilerin aktarıldığı üçüncü kişileri bilme hakkında bilgi edinebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerin eksik veya yanlış
işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan
işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
isteyebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>KVKK’nın ve ilgili diğer kanun
hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren
sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
üçüncü kişilere bildirilmesini isteyebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>İşlenen verilerin münhasıran otomatik
sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir
sonucun ortaya çıkmasına itiraz edebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerin kanuna aykırı olarak
işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep
edebilir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİ SAHİPLERİNİN İSTEK VE
TALEPLERİ</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş., KVK
Kanunu’nun 10. maddesine uygun olarak kişisel veri sahibinin haklarını
kendisine bildirmekte, bu hakların nasıl kullanılacağı konusunda kişisel veri
sahibine yol göstermektedir. Kişisel veri sahiplerinin haklarının
değerlendirilmesi ve kişisel veri sahiplerine gereken bilgilendirmenin
yapılması için KVK Kanunu’nun 13. maddesine uygun olarak gerekli kanalları, iç
işleyişi, idari ve teknik düzenlemeleri yürütmektedir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kişisel Verileri Koruma Kanunu’nun
ilgili kişinin haklarını düzenleyen 11. maddesi kapsamındaki taleplerinizi,
“Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e” göre SINIRLI
SORUMLU ÖZGELECEK A.Ş.'nin </span><span style='font-family:"Arial",sans-serif;
color:red'>­­­­­­­­­­­­­­­­­­______________(mail adresi) </span><span
style='font-family:"Arial",sans-serif'>adresine yazılı olarak iletebilirsiniz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kanun kapsamında uygun görülen süre 30
takvim günü içerisinde başvurunuza cevap verilmemesi durumunda veya
başvurunuzun reddedilmesi, başvurunuza verilen cevabı yetersiz bulmanız
durumlarında ilgili kişi olarak SINIRLI SORUMLU ÖZGELECEK A.Ş.'nin cevabını
öğrendiğiniz tarihten itibaren 30 gün ve her halükârda başvuru tarihini takiben
60 takvim günü içerisinde Kişisel Verilerin Korunması Kurulu’na şikâyette
bulunabilirsiniz.</span></p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>`


export const Kvkk = () => {




    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Box flex="1" p={2}>
                        {parse(html)}
                    </Box>
                </Grid>
            </Grid>
        </ >
    )

}
