import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { enqueueSnackbar } from 'notistack'
import { Logout } from '@mui/icons-material'
import { Form, Formik, useFormikContext } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, Card, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material'

import { FormikTextField, Iconify } from 'components'
import { useGetReferenceInformationQuery, useGetReferenceQuestionsQuery, useReferenceApproveReferenceInformationMutation, useReferenceApproveReferenceMutation, useReferenceRejectReferenceInformationMutation, useReferenceRejectReferenceMutation } from 'context'


// http://localhost:3000/referencer?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJlZmVyZW5jZXIxQG1lc3V0LmNvbSIsInVzZXJJZCI6IjY1YjhmYmUxYzczOWMwYTQ1NDBkYTM4ZiIsImlhdCI6MTcwODkzMjk3OCwiZXhwIjoxNzA5MTkyMTc4fQ.hWGRDdUnrcDfB-TP03fNZe-EaIqNScHAWvNHbp7eF7U
interface IReferencePageInitialValues {

    questionIndex: number,
    referenceInformation: {
        jobTitle: string,
        name: string,
        phoneNumber: string,
        relationWithCandidate: string,
        surname: string
    },

    referenceExplanation: string,
    referenceTests: Array<{
        answer: string,
        question: {
            _id: string
        }
    }>

}

export const ReferencerMainPage = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")
    const { data: referencerInfo, isFetching } = useGetReferenceInformationQuery({ token: token })

    const [reference_approveReference, { isLoading: approveLoading }] = useReferenceApproveReferenceMutation()
    const [reference_approveReferenceInformation, { isLoading: approveInformationLoading }] = useReferenceApproveReferenceInformationMutation()

    const [reference_rejectReference, { isLoading: rejectLoading }] = useReferenceRejectReferenceMutation()
    const [reference_rejectReferenceInformation, { isLoading: rejectInformationLoading }] = useReferenceRejectReferenceInformationMutation()




    const validationSchema = yup.object().shape({})

    const initialValues: IReferencePageInitialValues = {

        questionIndex: 0,
        referenceInformation: {
            jobTitle: referencerInfo?.referenceInformation?.jobTitle ?? "",
            name: referencerInfo?.referenceInformation?.name ?? "",
            phoneNumber: referencerInfo?.referenceInformation?.phoneNumber ?? "",
            relationWithCandidate: referencerInfo?.referenceInformation?.relationWithCandidate ?? "",
            surname: referencerInfo?.referenceInformation?.surname ?? "",
        },

        referenceExplanation: referencerInfo?.referenceExplanation ?? "",
        referenceTests: referencerInfo?.referenceTests ?? []

    }


    const handleAccept = async (values: IReferencePageInitialValues) => {


        try {

            let firstResponse = true
            if (referencerInfo?.referenceStatus === "pending") {
                const approveReferenceInformationResponse: any = await reference_approveReferenceInformation({
                    variables: {
                        referenceInformation: values.referenceInformation,
                        token: token ?? "",
                    }
                })
                console.log({ approveReferenceInformationResponse });
                firstResponse = approveReferenceInformationResponse?.data?.data?.reference_approveReferenceInformation?.success
            }


            if (firstResponse) {

                const approveReferenceResponse: any = await reference_approveReference({
                    variables: {
                        token: token ?? "",
                        referenceExplanation: values?.referenceExplanation,
                        referenceTests: values?.referenceTests
                    }
                })
                console.log({ approveReferenceResponse });

                if (approveReferenceResponse?.data?.data?.reference_approveReference?.success) {
                    enqueueSnackbar("Cevaplarınız başarıyla kaydedildi!")
                } else {
                    enqueueSnackbar("Kaydedilirken bir sorun oluştu.", { variant: 'error' })
                }

            } else {
                enqueueSnackbar("Bir hata oluştu", { variant: 'error' })
            }

        } catch (error) {

            console.log({ error });
            enqueueSnackbar("Bilgiler gönderilirken bir hata oluştu", { variant: 'error' })

        }

    }

    const handleReject = async () => {
        try {

            // let firstResponse = true
            if (referencerInfo?.referenceStatus === "pending") {
                const rejectReferenceInformationResponse: any = await reference_rejectReferenceInformation({
                    variables: {
                        token: token ?? "",
                    }
                })
                console.log({ rejectReferenceInformationResponse });
                // firstResponse = rejectReferenceInformationResponse?.data?.data?.reference_rejectReferenceInformation?.success
            }


            if (referencerInfo?.referenceStatus === "informationApproved") {
                const rejectReferenceResponse: any = await reference_rejectReference({
                    variables: {
                        token: token ?? "",
                    }
                })
                console.log({ rejectReferenceResponse });

                if (rejectReferenceResponse?.data?.data?.reference_rejectReference?.success) {
                    enqueueSnackbar("Successfully rejected!")
                } else {
                    enqueueSnackbar("Failed to reject", { variant: 'error' })
                }

            } else {
                enqueueSnackbar("Failed to approve information", { variant: 'error' })
            }

        } catch (error) {

            console.log({ error });
            enqueueSnackbar("Somethiong went wrong", { variant: 'error' })

        }
    }


    const loading = approveLoading || approveInformationLoading || rejectLoading || rejectInformationLoading

    return (
        <Stack spacing={3}>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant='h1'>
                    Referans Bilgileri
                </Typography>

                {/* <IconButton onClick={() => navigate("/")}>
                    <Logout />
                </IconButton> */}
            </Stack>

            {
                isFetching ? (
                    <Stack alignItems="center" justifyContent="center" my={5}>
                        <CircularProgress color='primary' />
                    </Stack>
                ) : (
                    !referencerInfo ? (
                        <Stack>
                            Geçerli bir linkten geliniz!
                        </Stack>
                    ) : (
                        referencerInfo?.referenceStatus !== "referenceApproved" ? (
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values: IReferencePageInitialValues, _actions) => {
                                    handleAccept(values)
                                }}
                            >
                                <Form>
                                    <Stack spacing={3}>

                                        <ReferenceInformationForm />

                                        <ReferenceTestForm />

                                        <Stack direction="row" gap={3} justifyContent="space-between" >
                                            <LoadingButton
                                                color='error'
                                                variant='outlined'
                                                onClick={handleReject}
                                                loading={loading}
                                            >
                                                Reddet
                                            </LoadingButton>

                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                color='primary'
                                                disabled={loading}
                                                loading={loading}
                                            >
                                                Onayla
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                </Form>
                            </Formik>
                        ) : (
                            <Stack>
                                Paylaştığınız bilgiler için teşekkür ederiz.
                            </Stack>
                        )
                    )
                )
            }

        </Stack>
    )
}





// ----------------------------------------------------------------
// ####### Information form
// ----------------------------------------------------------------
const ReferenceInformationForm = () => {

    return (
        <Card sx={{ p: 3, mb: 5 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormikTextField name="referenceInformation.name" label="Adı" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField name="referenceInformation.surname" label="Soyadı" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField name="referenceInformation.jobTitle" label="Mesleği" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField name="referenceInformation.phoneNumber" label="Telefon Numarası" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField name="referenceInformation.relationWithCandidate" label="Yakınlığı" />
                </Grid>
            </Grid>
        </Card>
    )
}


// ----------------------------------------------------------------
// ####### Information form
// ----------------------------------------------------------------
const ReferenceTestForm = () => {

    const { values, setFieldValue } = useFormikContext<IReferencePageInitialValues>()
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")

    const { data: questions, isFetching } = useGetReferenceQuestionsQuery({ token })


    return (
        <Box>
            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Lütfen aşağıda size ayrılmış alana adayla ilgili olumlu, olumsuz veya çekimser olduğunuz düşüncelerinizi paylaşınız. Adayla ilgili “işvereni aydınlatacak gerçek” bilgiler aktarınız. Bu bilgileri aktarırken işverenle empati yapmaya ve bulunacağı işe ne gibi katkılar sunacağından bahsediniz.
                </Typography>
            </Box>

            <Card sx={{ p: 3, mb: 5 }}>
                {
                    isFetching ? (
                        <>
                            Loading...
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    p: 2,
                                    gap: 1,
                                    flexWrap: 'wrap'
                                }}
                            >
                                {
                                    values?.questionIndex < 5 && <Typography fontWeight={'bold'} >{values?.questionIndex + 1}. Soru /  {questions?.data.length} </Typography>

                                    // questions?.data?.map((item: any, index: any) => {
                                    //     const list = values?.referenceTests
                                    //     const isAnswered = list?.find((i: any) => i?.question?._id === item?._id)

                                    //     return (
                                    //         index 
                                    //         // <IconButton key={index}
                                    //         //     sx={{
                                    //         //         flexShrink: 1,
                                    //         //         // flexGrow: 1,
                                    //         //         // flex: 1,
                                    //         //         width: '40px',
                                    //         //         height: '40px',
                                    //         //         backgroundColor: values?.questionIndex === index ? 'primary.light' : isAnswered ? '#f1f1f1' : 'secondary.light'
                                    //         //     }}
                                    //         //     onClick={() => setFieldValue("questionIndex", index)}
                                    //         // >
                                    //         //     {index + 1}
                                    //         // </IconButton>
                                    //     )
                                    // })
                                }
                            </Box>


                            <Box>

                                {
                                    questions?.data[values?.questionIndex] ? (
                                        <>
                                            <ReferenceTestQuestionItem question={questions?.data[values?.questionIndex]} />
                                        </>
                                    ) : (
                                        <>
                                            Test Bitti
                                        </>
                                    )
                                }
                            </Box>


                        </>
                    )
                }
            </Card>

            <Card sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormikTextField
                            multiline
                            minRows={4}
                            name="referenceExplanation"
                            label="Aday hakkındaki düşünceleriniz"
                        />
                    </Grid>
                </Grid>
            </Card>

        </Box>
    )
}


const ReferenceTestQuestionItem = ({ question, questionType }: any) => {


    const { values, setFieldValue } = useFormikContext<IReferencePageInitialValues>()

    const handleClick = (answer: any) => {

        const isConsist = values?.referenceTests?.find(item => item.question._id === question._id)

        if (!!isConsist) {
            const newList = values?.referenceTests?.filter(item => item.question._id !== question._id)
            setFieldValue("referenceTests", [
                ...newList,
                {
                    "answer": answer,
                    "question": {
                        "_id": question?._id
                    }
                }
            ])
        } else {
            setFieldValue("referenceTests", [
                ...values.referenceTests,
                {
                    "answer": answer,
                    "question": {
                        "_id": question?._id
                    }
                }
            ])
        }

        setFieldValue("questionIndex", values.questionIndex + 1)
    }

    const renderAnswers = (index: number) => {

        // if (!question?.isReverseTest) {
        switch (index) {
            case 1: return "Kesinlikle katılmıyorum."
            case 2: return "Katılmıyorum."
            case 3: return "Katılıyorum."
            case 4: return "Kesinlikle katılıyorum."
            case 5: return "Kararsızım."
            default: return ""
        }
        // } else {
        //     switch (index) {
        //         case 5: return "Kesinlikle katılmıyorum."
        //         case 4: return "Katılmıyorum."
        //         case 3: return "Kararsızım."
        //         case 2: return "Katılıyorum."
        //         case 1: return "Kesinlikle katılıyorum."
        //         default: return ""
        //     }
        // }
    }


    return (
        <Box>
            <Typography>
                {question?.content}
            </Typography>

            {question?.content ? (<Box
                sx={{
                    display: 'flex',
                    p: 2,
                    gap: 1,
                    flexWrap: 'wrap'
                }}
            >
                {
                    [1, 2, 3, 4]?.map((item, index) => {

                        const fieldTestResults = values?.referenceTests

                        const findedItem: any = fieldTestResults?.find((item: any) => item?.question?._id === question?._id)

                        return (
                            <>
                                <Grid item key={index} xs={6} sm={12 / 4}>
                                    <Button
                                        sx={{
                                            flexShrink: 1,
                                            width: '100%',
                                            height: '60px',
                                            backgroundColor: (findedItem && (findedItem?.answer === item)) ? 'primary.main' : 'secondary.light'
                                        }}
                                        onClick={() => handleClick(question?.isReverseTest ? (6 - item) : item)}
                                    >
                                        <Typography fontWeight={'bold'} >{renderAnswers(item)}</Typography>
                                    </Button>
                                </Grid>
                                {/* <IconButton key={index}
                                sx={{
                                    flexShrink: 1,
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: (findedItem && (findedItem?.answer === item)) ? 'red' : '#ccc'
                                }}
                                onClick={() => handleClick(item)}
                                >
                                {index}
                            </IconButton> */}
                            </>
                        )
                    })
                }
            </Box>) : null}
        </Box>
    )
}