

export const routes = {
    auth: {
        register: "/register",
        registerSuccessfull: "/register-successfull",
        registerVerfiy: "/confirmation",

        login: "/login",
        forgotPassword: "/forgot-password",
        forgotPasswordSuccessfull: "/forgot-password-successfull",
        resetPassword: "/reset-password",
    },
    candidate: {
        onboarding: {
            main: "/onboarding",
        },
        dashboard: {
            main: "/",
            myAccount: "/my-account",
            myReferences: "/my-references",
            aboutCompany: "/company",

            ozgeleceklerim: "/ozgeleceklerim",
            ozgelecekCreate: "/create-ozgelecek",
            ozgelecekUpdate: "/update-ozgelecek",

            advertisements: "/ilanlar",
            advertisementDetails: "/ilanlar",

            applications: "/basvurularim",

            helpCenter: "/yardım-merkezi",
            settings: "/ayarlar",

            indir: "/indir"
        },
    },
    company: {
        onboarding: {
            main: "/onboarding",
        },
        dashboard: {
            main: "/",
            myCompany: '/my-company',
            aboutCompany: "/sirket-hakkinda",

            myAdvertisements: '/my-advertisements',
            advertisementDetails: "/ilanlar",
            createAdvertisement: '/create-advertisement',
            updateAdvertisement: "/update-advertisement",

            applications: "/basvurular",
            applicationDetails: '/basvuru-detayi',
            radar: "/radar",
            havuz: "/aday-havuzu",
            credits: "/kredilerim",

            helpCenter: "/yardim-merkezi",
            settings: "/ayarlar",
        },
    },
    referencer: {
        main: "/referans"
    },
    anonymous: {
        main: "/",
        whatIsOzgelecek: "/ozgelecek-nedir",
        findAdvertisement: "/ilan-bul",
        advertisementDetails: "/ilan-detay",
        about: "/hakkinda",
        referencer: "/reference",
        contact: "/iletisim",
        faq: "/sss",
        firmaCozumleri: "/firma-cozumleri",
        kvkk: "/kvkk",
        onBilgilendirme: "/on-bilgilendirme-formu",
        teslimatveIade: "/teslimat-ve-iade-kosullari",
        blog: "/blog",
        blogDetail: "/blog/:slug",

    }
}