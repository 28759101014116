import { Grid, Typography, List, ListItem, ListItemText, Box, Stack, Button } from '@mui/material';
import { Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation, HandshakeAnimation, DashboardOrnek } from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { CreateInformationFormSection } from 'sections';



export const WhatIsOzgelecekPage = () => {



    return <>
        <Grid container spacing={2} >
            <Grid item xs={12} md={6} >
                <Box flex="1" p={2}>
                    <Typography variant="h1" mt={2} color="primary.main">Özgelecek nedir?</Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Kullanıcılarına geniş bir iş ilanı yelpazesi sunarak iş arama sürecini daha etkili hale getirir ve işverenlere de nitelikli adaylara daha hızlı ulaşma imkanı sağlar. Ayrıca, yapay zeka destekli özellikleriyle kullanıcıların yeteneklerini ve deneyimlerini daha iyi eşleştirebilir, böylece işe alım sürecinde verimliliği artırır.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Özgelecek, iş dünyasında dijitalleşme ve teknoloji kullanımının önemini vurgulayarak, işgücü piyasasında yenilikçi bir yaklaşım sunar ve sektördeki geleneksel işe alım pratiklerini dönüştürür.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Özgelecek, <strong>adayların</strong> deneyim, tecrübe, önceki çalışma geçmişi vb. kriterlerinden ziyade <strong>Özgelecek’leriyle;</strong> yani ilgi, motivasyon, yetenek, çalışma isteği gibi kriterlerle değerlendirilerek <strong>işe alınmasını sağlar.</strong>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={AboutAnimation}
                    style={{ width: '100%', height: '100%' }}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2} bgcolor={"secondary.lighter"} mt={10} p={3} id="section1" >
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={KariyerAnimation}
                    style={{ width: '80%', height: '100%' }}
                />
            </Grid>
            <Grid item xs={12} md={6} >
                <Box flex="1" p={2}>
                    <Typography variant="h3" mt={2} color="primary.main">Özgelecek İle Kariyer Planlama Nasıl Yapılıyor?</Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Özgelecek ile kariyer planlama yapmak oldukça kolay ve etkili bir süreçtir.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        İlk adım olarak, kullanıcılar profil oluştururlar ve bu profildeki beceri, deneyim ve kariyer hedefleri gibi bilgileri belirtirler. Platform, bu bilgileri kullanarak kullanıcıların özgeçmişlerini zenginleştirir ve potansiyel iş fırsatlarıyla eşleştirir. Ayrıca, Özgelecek kullanıcılarına kariyer önerileri ve gelişim imkanları sunar.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Kullanıcılar, kariyer hedeflerine uygun eğitim programlarına, seminerlere veya iş deneyimi fırsatlarına kolayca erişebilirler. Böylece, Özgelecek kullanıcıları kariyerlerini planlama ve geliştirme konusunda adım adım rehberlik alarak daha bilinçli ve başarılı kararlar verebilirler.
                    </Typography>
                </Box>
            </Grid>
        </Grid>

        <Grid container spacing={2} mt={5} id="section2" >
            <Grid item xs={12} md={6} >
                <Box flex="1" p={2}>
                    <Typography variant="h2" mt={2} color="primary.main">Geleceğin Senin Elinde</Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Her birey, kendi geleceğini planlama ve şekillendirme konusunda büyük bir etkiye sahiptir.

                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Özgelecek, en temelde bu etkiyi yaratmak için alan ve imkan tanımaktadır. Adaylar/kullanıcılar gerçekten de gelecekte çalışmak istedikleri işi Özgelecek sayesinde; geçmişi tamamen referans almadan “o işte ne kadar çalışmak istediklerini” gösterebilir. 
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Test sorularına samimiyetle verilen yanıtlar, Özgelecek sorularının metin veya video ile (tercih edilen) içtenlikle yanıtlanması adayların hızlı bir şekilde hayal ettikleri işe yerleşmesini sağlar. 
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={FutureAnimation}
                    style={{ width: '70%', height: '100%' }}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2} bgcolor={"secondary.lighter"} mt={10} p={3} id="section3" >
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={HistoryAnimation}
                    style={{ width: '80%', height: '100%' }}
                />
            </Grid>
            <Grid item xs={12} md={6} >
                <Box flex="1" p={2}>
                    <Typography variant="h3" mt={2} color="primary.main">Geçmişi Silmiyoruz, Geride Bırakıyoruz</Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Özgelecek, sistemi ile “geçmişin tamamen silindiği” bir dünyadan bahsetmez, böyle bir şey iddia etmez. 
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Özgelecek, vizyon itibariyle gerek iş arayan bireylerin gerekse çalışan arayan firmaların “tamamen geçmiş deneyime” odaklı bir şekilde işe alım yapmasından/işe yerleşmesinden ziyade; adayların “gelecekteki kendilerinden” alacakları verim, mutluluk ve çalışma motivasyonuna odaklanmalarını sağlar.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Bu sayede Özgelecek vizyonu, çalışanlara mutlu bir çalışma hayatı, işverenlere ise verimli bir ekip üyesi kazandırmış olur. 
                    </Typography>
                </Box>
            </Grid>
        </Grid>

        <Grid container spacing={2} mt={5} id="section4">
            <Grid item xs={12} md={6} >
                <Box flex="1" p={2}>
                    <Typography variant="h2" mt={2} color="primary.main">Gelecekteki Sen İle Tanış</Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Özgelecek ile gelecekteki “sen”e hazır mısın? 
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Özgelecek, sistemi ve vizyonu itibariyle adayları geleceğe taşımak; gelecekteki benlikleriyle tanıştırmak için kurulmuştur. 
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                    Özgelecek ile artık tüm çalışanlar gelecekte hangi işi yapmak istiyorlarsa o işe etkin bir sistem ile kavuşmuş olacaksınız! Üstelik geçmişe yönelik yargılar, deneyime takılan tipik sorunlar olmadan; söz konusu işlere hemen ulaşabilirsiniz!  
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={HandshakeAnimation}
                    style={{ width: '70%', height: '100%' }}
                />
            </Grid>
        </Grid>

        <Grid container spacing={2} mt={3}>
            <Grid item xs={12}>
                <Box bgcolor={"primary.main"} py={0} px={10} position={"relative"}>

                    <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }} alignItems="center" justifyContent="space-between">
                        {/* <Box component="img" src={Rectangle} alt="Rectangle" width="100%" height="auto" /> */}

                        <Stack direction="column" alignItems="flex-start" justifyContent="center" >
                            <Typography
                                pt={10}
                                fontSize={{ xs: 25, sm: 30, md: 35 }}
                                fontWeight={700}
                                color="white"
                                zIndex={2}
                            >
                                Özgeleceğinizi hemen oluşturmak için tıklayın!
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                    mt: 5,
                                    padding: 3,
                                    mb: 8,
                                    bgcolor: 'white', color: 'primary.main',
                                    '&:hover': {
                                        bgcolor: '#ccc',
                                        color: 'primary.dark'
                                    }
                                }}
                                onClick={() => {
                                    window.location.href = process.env.REACT_APP_APP_URL ?? ""
                                }}
                            >
                                Özgelecek Oluştur
                            </Button>
                        </Stack>

                    </Stack>

                </Box>
            </Grid>
        </Grid>

        {/* <Stack>
            <CreateInformationFormSection />
        </Stack> */}
    </ >

}
