
import { Box, Stack, Typography, Button, Container, } from '@mui/material'

import { Iconify } from 'components';
import { logoutReducer, useAppDispatch } from 'context'
import { Logo, NavbarPattern, References1 } from "assets";
import { Outlet } from 'react-router-dom';





export const ReferencerLayout = () => {

    const dispatch: any = useAppDispatch()

    const handleLogout = () => {
        dispatch(logoutReducer({}))
        document.location.reload();
    }


    return (
        <Stack direction="row" sx={{ height: '100%', }} >

            <Stack
                direction="column"
                justifyContent='space-between'
                sx={{
                    width: { xs: '300px', lg: '560px' },
                    backgroundSize: 'cover',
                    backgroundColor: '#F8F8FD',
                    borderRight: '2px solid #dedede',
                    backgroundImage: `url(${NavbarPattern})`,
                    minWidth: '360px',
                    maxWidth: '560px',
                    display: { xs: 'none', md: 'flex' },
                    overflow: 'auto',
                }}
            >
                <Box sx={{ mb: 2, mt: 6, ml: 6, justifyContent: 'left', display: 'flex' }}>
                    <img height={"60px"} src={Logo} alt="InternIF Logo" />
                </Box>

                <Box sx={{ ml: 6, mb: 2, mr: 10 }}>
                    <Typography variant="h2" sx={{ fontWeight: 700, color: 'primary.darker' }}>
                        Özgelecekte Referans Olmak!
                    </Typography>
                    <Typography variant="body2" sx={{ pt: 3, fontWeight: 500, color: 'primary.darker' }}>
                    Özgelecek’te referans olmak; referans olduğunuz adayın “yapmak istediği/hayal ettiği” işe giden yolda ona destek olmak demektir. Bu desteği sunarken vereceğiniz yanıtları doğru ve büyük bir içtenlikle vermenizi rica ederiz.                    </Typography>
                </Box>

                <Box sx={{ ml: 6, mb: 2, mr: 10, display: 'flex', justifyContent: 'center' }}>
                    <img src={References1} alt="onboarding-1" width={"70%"} />
                </Box>

            </Stack>


            <Container sx={{ p: { xs: 2, sm: 3 }, pr: { xl: 15 }, pt: 5, ml: 0, overflow: 'auto', flex: 1, }}>

                <Box
                    component="img"
                    src={Logo}
                    height={90}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        m: 'auto',
                        my: 3,
                    }}
                />

                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 3 }} flexWrap="wrap" gap={3}>
                    <Typography variant="h1" sx={{ fontWeight: 700, color: 'primary.dark' }}>
                        Ön Kayıt
                    </Typography>

                    <Button variant={"text"} size='small' color={"primary"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={() => handleLogout()}>
                        Çıkış Yap <Iconify icon={"mdi:logout"} sx={{ ml: 1 }} />
                    </Button>
                </Stack> */}

                <Box sx={{ py: 3 }}>
                    <Outlet />
                </Box>

            </Container>

        </Stack>
    )

}
