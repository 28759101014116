import Lottie from 'lottie-react';
import { ContactAnimation, } from 'assets';
import { Grid, Typography, Stack } from '@mui/material';
import { CreateInformationFormSection } from 'sections';
import { Iconify } from 'components';



export const ContactPage = () => {



    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={6} >
                <Typography variant="h1" mt={2} textAlign={"center"} color="primary.main">İletişim</Typography>

                <Stack flex="1" p={2} sx={{ '&>*': { textAlign: "justify !important" } }}>
                    <Typography variant="body1" textAlign={"center"}>
                        Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"center"}>
                        İşverenlere geniş bir aday havuzu sunarak işe alım süreçlerini daha etkili hale getirir ve iş arayanlara da nitelikli iş fırsatlarına daha hızlı ulaşma imkanı sağlar. Ayrıca, çeşitli işveren markaları için özelleştirilmiş işe alım süreçleri oluşturarak, işverenlerin işe alım süreçlerini daha verimli hale getirir.
                    </Typography>
                </Stack>


                <Stack flex="1" p={2} sx={{ '&>*': { textAlign: "justify !important" } }}>
                    <Typography variant="h3" mb={1} textAlign={"left"} color="primary.main">İletişim Bilgileri</Typography>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                        <Iconify icon={"line-md:phone-call-loop"} />
                        <Typography fontSize="16px" fontWeight={"bold"} textAlign={"center"} component={"a"} href="tel:+908508508191" color={"#222"}>
                            0850 850 8191
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" component={"a"} color={"#222"} gap={2} mt={1} href="mailto:bilgi@ozgelecek.net">
                        <Iconify icon={"line-md:email"} />
                        <Typography fontSize="15px" fontWeight={"bold"} textAlign={"center"}>
                            bilgi@ozgelecek.net
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2} mt={1}>
                        <Iconify icon={"line-md:my-location-loop"} />
                        <Typography fontSize="14px" textAlign={"center"} fontWeight={"bold"} color={"#222"}>
                        İZMİR BİLİŞİM VADİSİ - Gülbahçe Mah. İçmeler Cad. No: 14 İçkapı No: B05 Urla/İzmir
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>

            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={ContactAnimation}
                    style={{ width: '60%', height: '100%' }}
                />
            </Grid>

            <Grid item xs={12}>
                <CreateInformationFormSection />
            </Grid>
        </Grid>
    )

}
