import { Grid, Typography, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import { Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation, HandshakeAnimation, AboutUsAnimation } from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { Caner, Erdem, Hilal, Percihan, Yigit } from 'assets';
import parse from 'html-react-parser';

const html = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Helvetica;
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Üst Bilgi Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Alt Bilgi Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.stBilgiChar
	{mso-style-name:"Üst Bilgi Char";
	mso-style-link:"Üst Bilgi";}
span.AltBilgiChar
	{mso-style-name:"Alt Bilgi Char";
	mso-style-link:"Alt Bilgi";}
.MsoChpDefault
	{font-size:11.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:42.55pt 42.55pt 42.55pt 42.55pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=TR link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-family:"Arial",sans-serif'>TESLİMAT VE İADE KOŞULLARI </span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-family:"Arial",sans-serif'>(MESAFELİ SATIŞ SÖZLEŞMESİ)</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
1 – TARAFLAR</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>1.1.
SAĞLAYICI</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Sağlayıcı
Unvanı: </span></b><span style='font-family:"Arial",sans-serif'>ÖZGELECEK İNSAN
KAYNAKLARI TEKNOLOJİLERİ ANONİM ŞİRKETİ ( Sözleşmenin devamında “SAĞLAYICI”
olarak anılacaktır.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Sağlayıcı
Tüzel Kişi Adına İşlem Yapan Yetkili Kişi:</span></b><span style='font-family:
"Arial",sans-serif'> ERDEM DAĞKAYA</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Sağlayıcı
Vergi No:</span></b><span style='font-size:10.0pt;line-height:107%;font-family:
Helvetica;color:#333333;background:white'>6900520381</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Sağlayıcı
Adres:</span></b><span style='font-family:"Arial",sans-serif'>Erzene Mah.
Gençlik Cad. No:11 Bornova / İZMİR</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Sağlayıcı
Telefon:</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>1.2.
HİZMET ALAN</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Hizmet
Alan Ad-Soyad/Unvanı: </span></b><span style='font-family:"Arial",sans-serif'>_________________________(Sözleşmenin
devamında  “HİZMET ALAN” olarak anılacaktır.)</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Hizmet
Alan T.C./Vergi No:</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Hizmet
Alan Tüzel Kişi Adına İşlem Yapan Yetkili Kişi (Alıcı Tüzel Kişi İse):</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Hizmet
Alan Adres:</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Hizmet
Alan Tel:</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
2 - TANIMLAR</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.1.
Dijital İçerik:</span></b><span style='font-family:"Arial",sans-serif'>
Bilgisayar programı, uygulama, oyun, müzik, video ve metin gibi dijital şekilde
sunulan her türlü veriyi, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.2.
Hizmet:</span></b><span style='font-family:"Arial",sans-serif'> Bir ücret veya
menfaat karşılığında yapılan ya da yapılması taahhüt edilen mal sağlama
dışındaki her türlü tüketici işleminin konusunu, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.3.
Kalıcı Veri Saklayıcısı:</span></b><span style='font-family:"Arial",sans-serif'>
Tüketicinin gönderdiği veya kendisine gönderilen bilgiyi, bu bilginin amacına
uygun olarak makul bir süre incelemesine elverecek şekilde kaydedilmesini ve
değiştirilmeden kopyalanmasını sağlayan ve bu bilgiye aynen ulaşılmasına imkan
veren kısa mesaj, elektronik posta, internet, disk, CD, DVD, hafıza kartı ve
benzeri her türlü araç veya ortamı, ç) Kanun: 6502 sayılı Tüketicinin Korunması
Hakkında Kanunu, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.4.
Mesafeli Sözleşme:</span></b><span style='font-family:"Arial",sans-serif'>
Satıcı veya sağlayıcı ile tüketicinin eş zamanlı fiziksel varlığı olmaksızın,
mal veya hizmetlerin uzaktan pazarlanmasına yönelik olarak oluşturulmuş bir
sistem çerçevesinde, taraflar arasında sözleşmenin kurulduğu ana kadar ve
kurulduğu an da dahil olmak üzere uzaktan iletişim araçlarının
kullanılmasısuretiyle kurulan sözleşmeleri, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.5.
Sağlayıcı:</span></b><span style='font-family:"Arial",sans-serif'> Kamu tüzel
kişileri de dahil olmak üzere ticari veya meslekiamaçlarla tüketiciye ve/veya
hizmet alana hizmet sunan ya da hizmet sunanın adına ya da hesabına hareket
eden gerçek veya tüzel kişiyi, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.6.
Tüketici:</span></b><span style='font-family:"Arial",sans-serif'> Ticari veya
mesleki olmayan amaçlarla hareket eden gerçek veya tüzel kişiyi, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.7.
Hizmet Alan:</span></b><span style='font-family:"Arial",sans-serif'>İşbu
sözleşmeye konu hizmeti satın alan gerçek veya tüzel kişi,</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.7.
Uzaktan İletişim Aracı:</span></b><span style='font-family:"Arial",sans-serif'>
Mektup, katalog, telefon, faks, radyo, televizyon, elektronik posta mesajı,
kısa mesaj, internet gibi fiziksel olarak karşı karşıya gelinmeksizin sözleşme
kurulmasına imkan veren her türlü araç veya ortamı, </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>2.8.
Platform: </span></b><span style='font-family:"Arial",sans-serif'>ÖZGELECEK
İNSAN KAYNAKLARI TEKNOLOJİLERİ ANONİM ŞİRKETİ’ne ait “ozgelecek.net” adresli
Web Sitesi</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
3 – SÖZLEŞMENİN KONUSU</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>3.1.
</span></b><span style='font-family:"Arial",sans-serif'>İşbu mesafeli satış
sözleşmesi (İşbu sözleşmenin devamında “Sözleşme” olarak anılacaktır.) HİZMET
ALAN’ın SAĞLAYICI’ya ait Platform üzerinden satın alma talebi göndereceği ve aşağıda
özellikleri belirtilen hizmetin satışına ilişkin olarak SAĞLAYICI ve HİZMET
ALAN taraf, HİZMET ALAN tüketici ise 6502 sayılı Tüketicinin Korunması Hakkında
Kanun’daki hükümler gereğince, arasındaki hak ve yükümlülüklerin belirlenmesine
ilişkindir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
4 – SÖZLEŞME KONUSU HİZMETİN ÖZELLİKLERİ, BEDELİ VE ÖDEME ŞEKLİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>4.1.
</span></b><span style='font-family:"Arial",sans-serif'>Sözleşme konusu hizmet,
SAĞLAYICI ÖZGELECEK İNSAN KAYNAKLARI TEKNOLOJİLERİ ANONİM ŞİRKETİ tarafından
HİZMET ALAN taraf olarak çalışan arayan ve insan kaynağı hizmetine ihtiyaç
duyan tüzel kişi ve/veya gerçek kişilere talep ettikleri kriterlere uygun
çalışan adayları yönlendirmek ve iş arayan kişilere özelliklerine uygun iş
imkanları sunmaktır. SAĞLAYICI sunduğu hizmet ile doğru iş ve doğru çalışanı
bir araya getirmek üzere taraflara danışmanlık vermeyi vaat eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>4.2.
</span></b><span style='font-family:"Arial",sans-serif'>Sözleşme bedeli
taraflarca tüm vergiler dahil __________TL (________türklirası) olarak
kararlaştırılmıştır. HİZMET ALAN taraf, _____ gün içinde kararlaştırılan bedeli
SAĞLAYICI’ya ödemelidir. Aksi halde SAĞLAYICI tarafın kararlaştırılan hizmeti
ifa yükümlülüğü ortadan kalkar. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>4.3.
</span></b><span style='font-family:"Arial",sans-serif'>Sözleşme bedeli HİZMET
ALAN tarafından SAĞLAYICI’nın hizmet sunduğu Platform’da mevcut, ÖDEME başlıklı
sayfası üzerinden online olarak yapılacaktır. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
5 – SÖZLEŞMENİN İFA YERİ VE TESLİM ŞEKLİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>5.1.</span></b><span
style='font-family:"Arial",sans-serif'>Sözleşmenin HİZMET ALAN tarafından
onaylanması ile yürürlüğe girdiği kabul edilir. HİZMET ALAN’ın SAĞLAYICI’dan
satın almış olduğu hizmetin ifası ile SAĞLAYICI borcunu yerine getirmiş olur. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>5.2.</span></b><span
style='font-family:"Arial",sans-serif'> Sözleşme konusu hizmetin ifası SAĞLAYICI’ya
ait Platform üzerinden gerçekleştirilir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
6 – HİZMET ALAN’IN ÖNCEDEN BİLGİLENDİRİLMESİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>6.1.
</span></b><span style='font-family:"Arial",sans-serif'>HİZMET ALAN, aşağıdaki
hususlarda, bu Sözleşme'nin HİZMET ALAN tarafından Platform üzerinden kabulü
ile kurulmasından ve ödeme yükümlülüğü altına girmesinden önce Platform üzerinden
“Ön Bilgilendirme Formu”, “Mesafeli Satış Sözleşmesi”,” KVKK Aydınlatma Metni”,
“Gizlilik ve Kişisel Verilerin Korunması Politikası”, “Çerez Politikası” ve “Kullanım
Koşulları ve Üyelik Sözleşmesi” kısımlarındaki tüm genel-özel açıklamaları
incelediğini, okuduğunu, anladığını ve kendisine gerekli bilgilendirmenin
yapıldığını kabul eder: </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
SAĞLAYICI’nın unvanı ve iletişim bilgileri ile güncel tanıtıcı bilgileri, </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
SAĞLAYICI tarafından uygulanan, HİZMET ALAN bilgileri için geçerli gizlilik,
veri kullanımı-işleme ve HİZMET ALAN’a elektronik iletişim kuralları ile HİZMET
ALAN’ın SAĞLAYICI’ya bu hususlarda verdiği izinler, HİZMET ALAN’ın kanuni
hakları, SAĞLAYICI’nın hakları ve Taraflar’ın haklarını kullanım usulleri, </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
Sözleşme konusu hizmet karşılığında kabul edilen ödeme yöntem-araçları ile Hizmetler’in
temel özellik-nitelikleri, vergiler dahil toplam fiyatı (ilgili masraflar da
dahil olmak üzere HİZMET ALAN’ın SAĞLAYICI’ya ödeyeceği toplam bedel), </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
HİZMET ALAN’ın cayma hakkına sahip olmadığı mal-hizmetler, </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
HİZMET ALAN’ın cayma hakkının olduğu durumlarda bu hakkını kullanma şartları,
süresi ve usulü ile hakkın süresinde kullanılmaması durumunda HİZMET ALAN’ın
cayma hakkını kaybedeceği, </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
HİZMET ALAN’ın Platformüzerinden dönem dönem uygulanabilecek çeşitli fırsatlara
ilişkin yararlanma koşullarının (özel şartlar) detaylarını, </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
Uyuşmazlık hallerinde HİZMET ALAN’ın SAĞLAYICI’ya şikayetlerini iletebileceği
iletişim bilgileri ile yasal başvurularını, yetkili mahkeme ve icra dairelerine
ve/veya 6502 Sayılı Kanun'un ilgili hükümlerine uygun olarak İlçe/İl Hakem
Heyetlerine ve Tüketici Mahkemeleri'ne yapabileceği.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
7 – GENEL HÜKÜMLER</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>7.1.</span></b><span
style='font-family:"Arial",sans-serif'>HİZMET ALAN, hizmetin temel nitelikleri,
tüm vergiler dahil satış fiyatı ve ödeme şekli ile teslimat ve cayma hakkı ile
kullanım şartlarına ilişkin ön bilgilerin tarafına ulaştığını, okuyup bilgi
sahibi olduğunu ve elektronik ortamda satış için gerekli teyid-onayları
verdiğini kabul eder. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>7.2.</span></b><span
style='font-family:"Arial",sans-serif'>SAĞLAYICI tarafından hizmetin sunulması
sonrasında herhangi bir sebepten dolayı, işlem yapılan kredi kartının ait
olduğu Banka/finansman kurumunun ürün bedelini Platform’a ödememesi halinde, hizmet
sunulmaz.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>7.3.</span></b><span
style='font-family:"Arial",sans-serif'>SAĞLAYICI’nın akdi-kanuni hakları ayrıca
ve her halükarda saklıdır. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
8 – CAYMA HAKKI</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>8.1.</span></b><span
style='font-family:"Arial",sans-serif'>HİZMET ALAN, mesafeli satış sözleşmesini
ya da buna karşılık gelen herhangi bir teklifi kabul etmeden önce işbu sözleşme
ile SAĞLAYICI tarafından bilgilendirildiğini peşinen kabul eder<span
style='color:red'>. </span></span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>8.2.</span></b><span
style='font-family:"Arial",sans-serif'> HİZMET ALAN, sözleşme konusu insan
kaynakları hizmetine ilişkin talebini ilettikten ve ilişkili ödemeyi yaptıktan sonra,
HİZMET ALAN’a SAĞLAYICI tarafından uygun çalışan bilgileri ve/veya işveren
bilgileri iletilmeden önce, işbu sözleşmeye konu insan kaynakları hizmetini
almak istemediğini belirterek, SAĞLAYICI'nın emek ve mesaisine karşılık
sözleşme bedelinin %10'u mahsup edilmek kaydıyla, ödediği hizmet bedellerini
geri alabilir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>8.3.</span></b><span
style='font-family:"Arial",sans-serif'> Cayma talebi, HİZMET ALAN tarafından, SAĞLAYICI’nın
mail adresi veya Platform üzerinden SAĞLAYICI’ya sunulabilir. SAĞLAYICI, HİZMET
ALAN’ın hizmetin henüz alınmadığı ancak SAĞLAYICI tarafından araştırma,
inceleme vs. bir çalışma yapılmış olan dönemde cayma hakkını kullanması
halinde, cayma bildiriminin ulaştığı tarihten itibaren hemen ürün bedelini SAĞLAYICI'nın
emek ve mesaisine karşılık sözleşme bedelinin %10'u mahsup edilmek kaydıyla iade
edeceğini taahhüt eder. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>8.4.</span></b><span
style='font-family:"Arial",sans-serif'> SAĞLAYICI cayma hakkının kendisine
iletilmesinin ardından, takip eden iş günü içerisinde HİZMET ALAN’ın bankasına
ilgili talebi iletecektir. HİZMET ALAN, ilgili ödeme tutarının karta yansıma
süresinin bankasına bağlı olarak değişiklik göstereceğini kabul eder.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
9 – KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN HÜKÜMLER</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>9.1.</span></b><span
style='font-family:"Arial",sans-serif'> HİZMET ALAN, Kişisel Verilerinin kalıcı
veri saklayıcı olan ÖZGELECEK İNSAN KAYNAKLARI TEKNOLOJİLERİ ANONİM ŞİRKETİ tarafından
özellikle aşağıda belirtilen amaçlar için kullanılabileceğini, Kişisel
Verilerin Korunması Hakkındaki Kanunu’nun 5. ve 6. maddelerinde belirtilen
şartlar ve amaçlar dahilinde toplandığını/toplanacağını,
işlendiğini/işleneceğini bildiğini kabul, beyan ve muvafakat eder. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>●
Kanunlarda açıkça öngörülmesi,● Fiili imkânsızlık nedeniyle rızasını
açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan
kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
korunması için zorunlu olması,● Bir sözleşmenin kurulması veya ifasıyla
doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
verilerin işlenmesinin gerekli olması,● SAĞLAYICI’nın herhangi bir hukuki
yükümlülüğünü yerine getirebilmesi için zorunlu olması,● İlgili kişinin kendisi
tarafından alenileştirilmiş olması,● Bir hakkın tesisi, kullanılması veya
korunması için veri işlemenin zorunlu olması,● İlgili kişinin temel hak ve
özgürlüklerine zarar vermemek kaydıyla, SAĞLAYICI’nın meşru menfaatleri için
veri işlenmesinin zorunlu olması,● Sunulan hizmetlere ilişkin planlamanın
yapılması, risk analizlerinin yapılması, yeni hizmetler geliştirilebilmesi,●
Her türlü pazarlama ve çağrı merkezi hizmetleri, ● SAĞLAYICI tarafından
memnuniyetinin ölçülmesi, arttırılması ve araştırılması, ● Şikayet ve/veya
önerilerinizin ilgili HİZMET ALAN işverenlere iletilebilmesi, işveren
tarafından verilen cevabın HİZMET ALAN iş arayan tarafına iletilebilmesi ●
Yapılan insan kaynakları hizmetine ilişkin kaydın iş arayanlar ile paylaşılması,●
Platform’unKVKK bölümünde yayınlanmış KVKK Aydınlatma Metni ve Gizlilik
Politikası’nda belirtilen sebepler,● Kimliğinizin doğrulanması, ● Kamu kurum ve
kuruluşları ile yargı mercileri tarafından bilgi talep edilmesi,● Kişisel bilgi
güvenliğinizin sağlanması,● İleride doğabilecek hukuki uyuşmazlıklarda delil
olarak ispat yükümlülüğü, ● Şirket içi iletişimin sağlanması,● Acil Durum
yönetimi,● Her türlü finansal raporlama ve risk analizi işlemleri,● Muhasebesel
işlemlerin yürütülmesi,● Platform’da meydana gelebilecek hataların giderilmesi,●
İmzalanan sözleşmeler ve protokoller neticesinde iş ve işlemleri ifa,● Veri
güvenliğinin en üst düzeyde sağlanması,● Veri tabanlarının oluşturulması,</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>9.2.</span></b><span
style='font-family:"Arial",sans-serif'> HİZMET ALAN, kişisel verilerinin
işlenip işlenmediğini öğrenme ve bu konuda bilgi talep etme, bu konudaki amacı
ve verilerinin kiminle paylaşıldığını bilme/öğrenme, kişisel verilerinde
düzeltme yapma, en önemlisi de KİŞİSEL VERİLERİNİN SİLİNMESİNİ VE YOK
EDİLMESİNİ İSTEME ve kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahip
olduğunu, bu konudaki tüm beyan ve taleplerini, Platform üzerinden veya <b><span
style='color:red'>…................…</span></b>mail adresinden iletebilir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
10 - ŞİKAYET VE İTİRAZ MERCİLERİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>10.1.</span></b><span
style='font-family:"Arial",sans-serif'>Sözleşme konusu ürün, hizmet ve
işlemlerle ilgili şikayet ve itirazların, Erzene Mah. Genlik Cad. No:11
Bornova/İZMİR adresine veya <b><span style='color:red'>…................…</span></b>mail
adresine yapılabilecektir. Sözleşmenin kurulduğu tarih bu sözleşme altında
yazan tarih veya internet üzerinden/Platformdan sözleşmenin onaylandığı tarihtir.
</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>10.2.</span></b><span
style='font-family:"Arial",sans-serif'>Sözleşmenin bir örneği HİZMET ALAN’a
mail yoluyla gönderileceği gibi HİZMET ALAN  bu metne Platfomdan da
ulaşabilecektir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
11 – SON HÜKÜMLER</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>11.1.</span></b><span
style='font-family:"Arial",sans-serif'>SAĞLAYICI, siparişe ilişkin verilen
belge ve bilgilerin eksik, sahte ve/veya yanlış olduğunun saptanması veya
siparişin kötü niyetle/veya ticari ve/veya kazanç elde etmek amacıyla yapılmış
olduğuna dair şüphenin varlığı ya da tespiti halinde, herhangi bir zamanda, HİZMET
ALAN’ı bilgilendirmek koşuluyla sipariş başvurusunu, gerekli incelemelerin
yapılmasını teminen durdurma ve/veya iptal etme hakkını saklı tutar. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>11.2.</span></b><span
style='font-family:"Arial",sans-serif'>İptal halinde, ödeme için iade süreci
yine HİZMET ALAN’a bildirilmek kaydıyla yapılabilir. Tüm bu bilgiler,
kullanılan uzaktan iletişim araçlarına uygun olarak ve iyiniyet ilkeleri
çerçevesinde ergin olmayanlar ile ayırtım gücünden yoksun ve kısıtlı erginleri
koruyacak şekilde ticari amaçlarla verilmiştir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
12 – MÜCBİR SEBEPLER</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>12.</span></b><span
style='font-family:"Arial",sans-serif'>Sözleşmenin imzalandığı tarihte mevcut
olmayan veya öngörülmeyen, tarafların kontrolleri dışında gelişen, ortaya
çıkması ile taraflardan birinin veya ikisinin sözleşme ile yüklendikleri borç
ve sorumluluklarını kısmen veya tamamen yerine getirmelerini veya bunları
kararlaştırılan zamanda yerine getirmelerinin önüne geçen durumlar mücbir sebep
olarak nitelendirilir. Mücbir sebep hallerinin (doğal afet, savaş, terör,
ayaklanma, salgın hastalık/pandemi, değişen mevzuat hükümleri, el koyma, grev,
lokavt, üretim tesislerinde önemli ölçüde arıza, iletişim ağının geçici veya
kalıcı olarak uzun süre kesilmesi) var olması durumunda, mücbir sebepten dolayı
sözleşmeden doğan borcunu ifa edemeyen taraf diğer tarafa durumu derhal
bildirecektir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>MADDE
13 – UYUŞMAZIKLARIN ÇÖZÜMÜ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>13.1.</span></b><span
style='font-family:"Arial",sans-serif'>İş bu sözleşmenin uygulanmasında, HİZMET
ALAN Tüketici ise, Sanayi ve Ticaret Bakanlığınca ilan edilen değere kadar
Tüketici Hakem Heyetleri ile SAĞLAYICI’nın yerleşim yerindeki TÜKETICI
MAHKEMELERI yetkilidir. Tarafların tüketici olmadığı diğer hallerde ise, işbu
sözleşmeden kaynaklı uyuşmazlıkların çözümünde İzmir Mahkemeleri ve İcra
Daireleri yetkilidir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>13.2.</span></b><span
style='font-family:"Arial",sans-serif'>Siparişin elektronik ortamda onaylanması
durumunda, HİZMET ALAN iş bu sözleşmenin tüm hükümlerini kabul etmiş sayılır.
Taraflar arasında ve/veya 3.şahısların şikayetleri ile ilgili herhangi bir
uyuşmazlık olduğunda SAĞLAYICI’nınkayıt ve belgeleri, mail yazışmaları,
internet sitesi üzerinde yer alan sistem kayıtları (bilgisayar-ses kayıtları
gibi manyetik ortamdaki kayıtlar dahil) münhasıran HMK 193. madde anlamında
kesin delil teşkil edecektir. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Bu
satış sözleşmesinde belirtilen bilgileri verdiğimizi ve bu surette
anlaştığımızı kabul, beyan ve taahhüt ederiz.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>SAĞLAYICI:</span></b><span
style='font-family:"Arial",sans-serif'>ÖZGELECEK İNSAN KAYNAKLARI TEKNOLOJİLERİ
ANONİM ŞİRKETİ</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>HİZMET
ALAN:</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Sözleşme
İmza/Onay Tarihi:</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

</div>

</body>

</html>
`


export const TeslimatveIade = () => {




	return (
		<>
			<Grid container spacing={2} >
				<Grid item xs={12} >
					<Box flex="1" p={2}>
						{parse(html)}
					</Box>
				</Grid>
			</Grid>
		</ >
	)

}
