import { Navigate, Route, Routes } from 'react-router-dom'

import {
    AdvertisementDetailsPage,
    FindAdvertisementPage,
    HomePage,
    WhatIsOzgelecekPage,
    AboutPage,
    ReferencerMainPage,
    FAQPage,
    FirmaCozumleri,
    ContactPage,
    Kvkk,
    OnBilgilendirmeFormu,
    TeslimatveIade,
    BlogPage,
    BlogDetailPage
} from 'pages';
import { routes } from './routes';
import { MainLayout } from 'layouts';



export const AnonymousRouter = () => {
    return (
        <>
            <Route path="/" element={<MainLayout />}>
                <Route path={routes.anonymous.main} element={<HomePage />} />
                <Route path={routes.anonymous.whatIsOzgelecek} element={<WhatIsOzgelecekPage />} />
                <Route path={routes.anonymous.findAdvertisement} element={<FindAdvertisementPage />} />
                <Route path={routes.anonymous.advertisementDetails + "/:id"} element={<AdvertisementDetailsPage />} />
                <Route path={routes.anonymous.about} element={<AboutPage />} />
                <Route path={routes.anonymous.blog} element={<BlogPage />} />
                <Route path={routes.anonymous.blog + "/:slug"} element={<BlogDetailPage />} />
                {/* <Route path={routes.anonymous.referencer} element={<ReferencerMainPage />} /> */}

                <Route path={routes.anonymous.faq} element={<FAQPage />} />
                <Route path={routes.anonymous.firmaCozumleri} element={<FirmaCozumleri />} />
                <Route path={routes.anonymous.contact} element={<ContactPage />} />
                <Route path={routes.anonymous.kvkk} element={<Kvkk />} />
                <Route path={routes.anonymous.onBilgilendirme} element={<OnBilgilendirmeFormu />} />
                <Route path={routes.anonymous.teslimatveIade} element={<TeslimatveIade />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </>
    )
}
