
import {
    anonymous_blogPosts, IAnonymousBlogPostsResponse, IAnonymousBlogPostsVariables,
    anonymous_blogPost, IAnonymousBlogPostResponse, IAnonymousBlogPostVariables
} from "./queries"
import { blogApi } from "../blogApi"

const anonymousBlogApi = blogApi.injectEndpoints({
    endpoints: (builder) => ({


        // ####################  Queries #################

        getAnonymoustBlogPosts: builder.query<IAnonymousBlogPostsResponse, IAnonymousBlogPostsVariables>({
            query: (variables: IAnonymousBlogPostsVariables) => {
                return ({
                    url: "",
                    body: anonymous_blogPosts(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_blogPosts
            },
            providesTags: ["anonymous_blog", "anonymous_blogs"]
        }),

        getAnonymoustBlogPost: builder.query<IAnonymousBlogPostResponse, IAnonymousBlogPostVariables>({
            query: (variables: IAnonymousBlogPostVariables) => {
                return ({
                    url: "",
                    body: anonymous_blogPost(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_blogPost
            },
            providesTags: ["anonymous_blog"]
        }),

    }),

    overrideExisting: false,
});

export const {
    useGetAnonymoustBlogPostsQuery, useLazyGetAnonymoustBlogPostsQuery,
    useGetAnonymoustBlogPostQuery, useLazyGetAnonymoustBlogPostQuery,
} = anonymousBlogApi;

