import { Grid, Typography, List, ListItem, ListItemText, Box, Divider, Stack } from '@mui/material';
import { Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation, HandshakeAnimation, AboutUsAnimation } from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { Caner, Erdem, Hilal, Percihan, Yigit } from 'assets';
import { CreateInformationFormSection } from 'sections';

const team = [
    {
        name: "Erdem Dağkaya",
        title: "CEO",
        email: "erdem@ozgelecek.net",
        image: Erdem
    },
    {
        name: "Caner Göztepe",
        title: "CTO",
        email: "caner@ozgelecek.net",
        image: Caner
    },
    {
        name: "Hilal Güneş",
        title: "CCO",
        email: "hilal@ozgelecek.net",
        image: Hilal
    },
    {
        name: "Yiğit Gülüş",
        title: "Business Consultant",
        email: "yigit@ozgelecek.net",
        image: Yigit
    },
    {
        name: "Percihan Zengin",
        title: "Psychology Consultant",
        email: "percihan@ozgelecek.net",
        image: Percihan
    }
]


export const AboutPage = () => {




    return (
        <>

            <Grid container spacing={2} >
                <Grid item xs={12} md={6} >
                    <Box flex="1" p={2}>
                        <Typography variant="h1" mt={2} color="primary.main">Hakkımızda</Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.
                        </Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Kullanıcılarına geniş bir iş ilanı yelpazesi sunarak iş arama sürecini daha etkili hale getirir ve işverenlere de nitelikli adaylara daha hızlı ulaşma imkanı sağlar. Ayrıca, yapay zeka destekli özellikleriyle kullanıcıların yeteneklerini ve deneyimlerini daha iyi eşleştirebilir, böylece işe alım sürecinde verimliliği artırır.
                        </Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek, iş dünyasında dijitalleşme ve teknoloji kullanımının önemini vurgulayarak, işgücü piyasasında yenilikçi bir yaklaşım sunar ve sektördeki geleneksel işe alım pratiklerini dönüştürür.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                    <Lottie
                        animationData={AboutUsAnimation}
                        style={{ width: '100%', height: '100%' }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Box flex="1" p={2}>
                        <Typography variant="h2" mt={2} color="primary.dark">Vizyonumuz</Typography>
                        <Divider />
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.
                        </Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Kullanıcılarına geniş bir iş ilanı yelpazesi sunarak iş arama sürecini daha etkili hale getirir ve işverenlere de nitelikli adaylara daha hızlı ulaşma imkanı sağlar. Ayrıca, yapay zeka destekli özellikleriyle kullanıcıların yeteneklerini ve deneyimlerini daha iyi eşleştirebilir, böylece işe alım sürecinde verimliliği artırır.
                        </Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek, iş dünyasında dijitalleşme ve teknoloji kullanımının önemini vurgulayarak, işgücü piyasasında yenilikçi bir yaklaşım sunar ve sektördeki geleneksel işe alım pratiklerini dönüştürür.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Box flex="1" p={2}>
                        <Typography variant="h2" mt={2} color="primary.dark">Misyonumuz</Typography>
                        <Divider />
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.
                        </Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Kullanıcılarına geniş bir iş ilanı yelpazesi sunarak iş arama sürecini daha etkili hale getirir ve işverenlere de nitelikli adaylara daha hızlı ulaşma imkanı sağlar. Ayrıca, yapay zeka destekli özellikleriyle kullanıcıların yeteneklerini ve deneyimlerini daha iyi eşleştirebilir, böylece işe alım sürecinde verimliliği artırır.
                        </Typography>
                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek, iş dünyasında dijitalleşme ve teknoloji kullanımının önemini vurgulayarak, işgücü piyasasında yenilikçi bir yaklaşım sunar ve sektördeki geleneksel işe alım pratiklerini dönüştürür.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Box flex="1" p={2}>
                        <Typography variant="h2" mt={2} color="primary.dark">Ekibimiz</Typography>
                        <Divider />
                        <Grid container spacing={2} mt={2}>
                            {team.map((member, index) => {
                                return <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                                    <Box display="flex" alignItems="center" justifyContent="center" px={2} py={4} bgcolor={index % 2 === 0 ? "secondary.lighter" : "secondary.light"} sx={{ cursor: 'pointer', '&:hover': { boxShadow: 5 }, borderWidth: 2, borderColor: "#ccc", borderStyle: "solid" }}>
                                        <Box display="flex" alignItems="center" flexDirection={"column"} justifyContent={"center"} >
                                            <img src={member.image} alt={member.name} style={{ width: 120, height: 130, borderRadius: 25, objectFit: "cover", boxShadow: '0 0 10px 0 #ccc' }} />
                                            <Box mt={3} mb={2} display={"flex"} flexDirection={"column"} gap={1}>
                                                <Typography variant="h5" textAlign={"center"} color="primary.main">{member.name}</Typography>
                                                <Typography variant="body1" textAlign={"center"} color="primary.dark">{member.title}</Typography>
                                                <Typography variant="body1" textAlign={"center"} color="primary.dark">{member.email}</Typography>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Grid>
                            })}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Stack>
                <CreateInformationFormSection />
            </Stack>

        </ >
    )

}


{/* <Box key={index} display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor={index % 2 === 0 ? "secondary.lighter" : "secondary.light"}>
                            <Box display="flex" alignItems="center" >
                                <img src={member.image} alt={member.name} style={{ width: 100, height: 100, borderRadius: 50 }} />
                                <Box ml={2}>
                                    <Typography variant="h5" color="primary.main">{member.name}</Typography>
                                    <Typography variant="body1" color="primary.dark">{member.title}</Typography>
                                    <Typography variant="body1" color="primary.dark">{member.email}</Typography>
                                </Box>
                            </Box>
                        </Box> */}