import { Grid, Typography, List, ListItem, ListItemText, Box, Divider, Stack } from '@mui/material';
import { Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation, HandshakeAnimation, SearchAnimation, Donusum, HrDanismanlik, Dart, } from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { CreateInformationFormSection } from 'sections';



export const FirmaCozumleri = () => {



    return <>

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h1" mt={2} textAlign={"center"} color="primary.main">Firma Çözümleri</Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12} md={12} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Stack flex="1" p={2}>
                    <Typography variant="body1" textAlign={"center"}>
                        Özgelecek, firmaların en çok ihtiyaç duyduğu ve en çok aradığı o “etkin, verimli, istekli ve çalışma motivasyonu yüksek” çalışanlara ulaşmasını sağlayan Türkiye’nin en inovatif insan kaynakları teknolojisidir.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"center"}>
                        Özgelecek, işe alım dünyasına kattığı vizyonuyla adayların geçmiş tecrübelerine binaen firmaya katacaklarını değil; gelecekte firmaya neler katacağı konusunda bir ölçümleme yaparak çeşitli eleme yöntemlerinden geçmiş, nihai olarak ortaya çıkan adaylarla işverenleri buluşturur.
                    </Typography>
                </Stack>
            </Grid>
            {/* <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={SearchAnimation}
                    style={{ width: '70%', height: '100%' }}
                    loop={false}
                />
            </Grid> */}
        </Grid >

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h3" mt={2} color="primary.dark">Etkin İlan; Doğru Çalışan</Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Stack flex="1" p={2}>
                    <Typography variant="body1" textAlign={"justify"}>
                        Özgelecek sistemi içerisinde birkaç dakika içerisinde etkin bir ilan vererek adayların Özgelecekleriyle başvuru yapmasını sağlayabilirsiniz. İlanlarınıza dopingler ekleyebilir, vitrinde gösterilmesini sağlayabilir veya acil olduğunu bildirecek etiketler ekleyebilirsiniz.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Bu sayede başvuru yapmış olan adayların Özgeleceklerinde hem profillerini görüntüleyebilir hem de çözdüğü Alan ve Motivasyon testlerinden aldığı puanlarla söz konusu adayın, işe olan “ilgisini” ve “motivasyonunu” toplam 100 puanlık bir skorlama üzerinden öğrenebilir, ölçülmüş adaylara hemen ulaşabilirsiniz.
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={Dart}
                    style={{ width: '50%', height: '100%' }}
                    loop={true}
                />
            </Grid>
        </Grid >

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h3" mt={2} color="primary.dark">Eşleştirme Sistemi: Özgelecek Radar!</Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Stack flex="1" p={2}>
                    <Typography variant="body1" textAlign={"justify"}>
                        Özgelecek Radar, siz daha ilan bilgilerinizi doldururken; arka tarafta çalışarak ilan verirken girdiğiniz parametrelerle size en uygun 10 adayı “Radar”ına alır ve ilan vermeden hemen önce size önerilen
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Özgelecek teknolojisi bu sayede, siz ilan vermeden, aday taraması yapmadan herhangi bir ek operasyon yürütmeden sizlere “aradığınız çalışan” adaylarını sunmuş olur.
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={HrDanismanlik}
                    style={{ width: '30%', height: '100%' }}
                    loop={true}
                />
            </Grid>
        </Grid >

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h3" mt={2} color="primary.dark">İşe Alım Danışmanlığı: Özgelecek Keşif</Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Stack flex="1" p={2}>
                    <Typography variant="body1" textAlign={"justify"}>
                        Özgelecek, sadece operasyonlarınızı yürütmenizi kolaylaştırmak ile kalmaz; sürecinizi tamamen delege edebileceğiniz bir danışmanlık sürecini de sizlere sunar.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Firma profilinizi oluşturduktan sonra; Keşif Danışmanlarımıza “aradığınız çalışanın özelliklerini” bildirerek tüm sürecin Özgelecek tarafından yürütülmesini; Özgelecek vizyonuyla taramaları yapılmış, mülakatları gerçekleştirilmiş, görüşme notları oluşturulmuş ve “işe girişten önceki tüm süreçleri tamamlanmış” adaylara kavuşmanızı sağlar.
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={SearchAnimation}
                    style={{ width: '50%', height: '100%' }}
                    loop={true}
                />
            </Grid>
        </Grid >

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h3" mt={2} color="primary.dark">Özgelecek Dönüşüm</Typography>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Stack flex="1" p={2}>
                    <Typography variant="body1" textAlign={"justify"}>
                        Özgelecek vizyonu, yalnızca işe alım yaparken değil; firmanızın içerisinde işe alım yapmadan mutluluk ve verimi artırmada da kullanacağınız bir sistemdir.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"justify"}>
                        Bu anlamda <strong><a href={"https://donusum.ozgelecek.net"}>donusum.ozgelecek.net</a></strong> web adresimizi hemen ziyaret edebilir, ayrıntılı bilgi alarak talepte bulunabilirsiniz!
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={Donusum}
                    style={{ width: '40%', height: '100%' }}
                    loop={true}
                />
            </Grid>
        </Grid >

        <Stack>
            <CreateInformationFormSection />
        </Stack>
    </ >

}
