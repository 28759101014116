import { ProductType } from "enums/payment/product/ProductType";
import { ExtraProductEnums, ProductTypeEnums } from "types";

export interface IAnonymousAdvertisementResponse {

  quotaVisibleToApplicant: string;


  _id: string;
  title: string
  productType: ProductTypeEnums,
  extraProducts: ExtraProductEnums[] | null | any,
  status: string
  startDate: string
  quota: string
  field: string
  department: string
  isApplied: boolean
  language: string
  description: string
  place: string
  companyInfoVisible: boolean
  workType: string
  locations: Array<{
    city: string
    districts: string[]
  }>
  positionLevel: string
  requiredAgeInterval: number
  requiredEducationLevel: Array<string>
  requiredSkills: string[]
  requiredLanguages: Array<{
    name: string
    reading: string
    writing: string
    listening: string
  }>
  militaryServiceRequired: string
  requiredDriverLicense: string
  images: string[]
  createdAt: string
  updatedAt: string
  createdBy: {

    _id: {
      _id: string
    }
    companyInformation: {
      // _id: string
      companyName: string
      phoneNumber: string
      email: string
      city: string
      district: string
      address: string
      profileImage: string
      sector: string
      companyAddress: string
      foundationYear: string
      numberOfEmployees: string
      website: string
      linkedin: string
      instagram: string
      facebook: string
      companyBrief: string
    }
  }


}

export interface IAnonymousAdvertisementVariables {
  advertisementId: string
}


export const anonymous_advertisement = (variables: IAnonymousAdvertisementVariables) => {

  const data = JSON.stringify({
    query: `
    query anonymous_advertisement($advertisementId: ID!) {
      anonymous_advertisement(advertisementId: $advertisementId) {
        _id
        title
        status
        productType
        extraProducts
        startDate
        quota
        quotaVisibleToApplicant
        field
        department
        language
        companyInfoVisible
        description
        place
        workType
        locations {
          city
          districts
        }
        positionLevel
        requiredAgeInterval
        requiredEducationLevel
        requiredSkills
        requiredLanguages {
          name
          reading
          writing
          listening
        }
        militaryServiceRequired
        requiredDriverLicense
        images
        isApplied
        createdBy {
          _id 
          companyInformation {
            companyName
            phoneNumber
            email
            city
            district
            address
            profileImage
            sector
            companyAddress
            foundationYear
            numberOfEmployees
            website
            linkedin
            instagram
            facebook
            companyBrief
          }
        }
        createdAt
        updatedAt
      }
    }
    `,
    variables: {
      "advertisementId": variables?.advertisementId
    }

  })

  return data

}