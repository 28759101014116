import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material'
import { AboutAnimation, FaqAnimation } from 'assets'
import Lottie from 'lottie-react'
import React from 'react'
import { CreateInformationFormSection, HelpCenterSection } from 'sections'

const sssList = [
    {
        title: "Özgelecek nedir ve nasıl çalışır?",
        content: "Özgelecek, gelecekteki potansiyeli vurgulayan ve yetenekleri, becerileri, motivasyonu ve ilgi alanlarını öne çıkaran bir işe alım platformudur. Bu platform, adayların gerçek yeteneklerine odaklanarak işverenlerle en uygun adayları buluşturur."
    },
    {
        title: "Özgelecek'in farkı nedir?",
        content: "Özgelecek, diğer platformlardan farklı olarak sadece geçmiş bilgilere ve deneyimlere değil, adayları yeteneklerine, becerilerine, ilgi alanlarına ve motivasyonlarına göre değerlendirerek işe alım süreçlerine destek sağlar. Adaylara yapay zeka destekli testler sunar. Firmalar için de, adayları ölçümleyerek başvuraları iletir."
    },
    {
        title: "Hangi alanlarda iş ilanları bulunabilir ve nasıl başvuru yapılır?",
        content: "Özgelecek'te farklı sektörlerde, meslek gruplarında ve birçok pozisyonda iş ilanları bulunabilir. ozgelecek.net’e ücretsiz kayıt olarak özgeleceğinizi oluşturabilir; yeteneklerinizi, becerilerinizi, motivasyonunuzu ve ilgi alanlarınızı detaylı bir şekilde belirtebilirsiniz. İş ilanlarını inceleyebilir, uygunluk testlerini tamamladıktan sonra ilanlara başvurabilirsiniz."
    },
    {
        title: "Özgelecek'te işverenler kimlerdir?",
        content: "Özgelecek'te geleceğe yönelik vizyonları olan ve yetenekleri önemseyen şirketler ve kurumlar bulunur. Bu şirketler, adayların potansiyelini değerlendirerek başarılı iş ilişkileri kurmayı hedeflerler."
    },
    {
        title: "Özgelecek'te firmalar nasıl işlem yapar ve hangi hizmetleri alabilir?",
        content: "Firmalar Özgelecek üzerinden iş ilanı yayınlayabilir, aday görüntüleyebilir, kişilik envanterleri uygulayabilir, Özgelecek Radar ve Keşif hizmetinden yararlanabilir ve dönüşüm hizmetleri alabilirler. Özgelecek Kredisi (ÖK) sistemiyle tüm bu hizmetleri tek bir kredi sistemiyle kullanabilirler."
    }
]


export const FAQPage = () => {
    return (
        <Grid container spacing={2} >

            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h1" mt={2} textAlign={"center"} color="primary.main">
                        Sık Sorulan Sorular
                    </Typography>
                    <Divider />
                </Box>
            </Grid>

            <Grid item xs={12} md={12} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Stack flex="1" p={2}>
                    <Typography variant="body1" textAlign={"center"}>
                        Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.
                    </Typography>
                    <Typography variant="body1" mt={2} textAlign={"center"}>
                        İşverenlere geniş bir aday havuzu sunarak işe alım süreçlerini daha etkili hale getirir ve iş arayanlara da nitelikli iş fırsatlarına daha hızlı ulaşma imkanı sağlar. Ayrıca, çeşitli işveren markaları için özelleştirilmiş işe alım süreçleri oluşturarak, işverenlerin işe alım süreçlerini daha verimli hale getirir.
                    </Typography>
                </Stack>
            </Grid>

            <Grid item xs={12} md={8} >
                <Card sx={{ p: 3 }}>
                    <HelpCenterSection list={sssList} />
                </Card>
            </Grid>

            <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Lottie
                    animationData={FaqAnimation}
                    style={{ width: '100%', height: '100%' }}
                />
            </Grid>

            {/* <Grid item xs={12}>
                <CreateInformationFormSection />
            </Grid> */}

        </Grid>
    )
}
