
import { AppBar, Box, Button, Container, Divider, FormControl, FormHelperText, Grid, IconButton, Drawer, List, MenuItem, Select, Stack, TextField, Toolbar, Typography, useMediaQuery, SwipeableDrawer, Dialog, DialogTitle, DialogContent, LinearProgress, DialogActions } from '@mui/material'
import { AppStore, GooglePlay, IyzicoImage, Logo, LogoWhite, OzgelecekMain, Pattern, JobHunt, OIB } from 'assets'
import { ICandidateAdvertisementsVariables, useGetCitiesQuery } from 'context'
import React, { ReactNode, useEffect } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Outlet, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { routes } from 'routes';
import { Google, Menu } from '@mui/icons-material';



interface IMainLayoutProps {
    children?: ReactNode | any
}

const initialVariables: ICandidateAdvertisementsVariables = {
    pagination: {
        page: 1,
        pageSize: 4,
    },
    filterOption: {

        filter: {
            title: null,
            city: "",
            department: null,
            description: null,
            district: null,
            field: null,
            language: null,
            militaryServiceRequired: null,
            place: null,
            positionLevel: null,
            requiredAgeInterval: null,
            requiredDriverLicense: null,
            requiredEducationLevel: null,
            requiredLanguages: [],
            requiredSkills: null,
            startDate: null,
            workType: null
        },

        search: {
            all: null,
            city: null,
            department: null,
            description: null,
            district: null,
            title: null
        }
    },

}

export const MainLayout = ({ children }: IMainLayoutProps) => {

    const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
    const [text, setText] = React.useState<string>('')
    const [variables, setVariables] = React.useState<ICandidateAdvertisementsVariables>(initialVariables)
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
    const path = useLocation().pathname
    const navigate = useNavigate()
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    const [progress, setProgress] = React.useState(0);

    const { pathname, hash } = useLocation();
    const [isSticky, setIsSticky] = React.useState(false);

    const handleScroll = () => {
        // Burada sticky durumunu kontrol ederek setIsSticky fonksiyonunu kullanarak güncelleyin.
        const scrollPosition = window.scrollY;
        if (scrollPosition > 0) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };


    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    useEffect(() => {
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        if (hash) {
            let elem = document.getElementById(window.location.hash.slice(1))
            if (elem) {
                console.log({ hash, elem });
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }

    }, [hash, pathname]);


    useEffect(() => {
        if (openDialog) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 200);
            return () => {
                clearInterval(timer);
            };
        }
    }, [openDialog]);

    useEffect(() => {
        if (progress === 100) {
            window.open(process.env.REACT_APP_APP_URL + "/ilanlar")
            setOpenDialog(false)
            setProgress(0)
        }
    }, [progress]);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Box sx={{ width: '100%', height: 5, bgcolor: 'primary.main' }} />
            {!downMd ? <AppBar position="sticky" sx={{ bgcolor: isSticky ? '#FFFFFFDD' : 'transparent', boxShadow: isSticky ? 5 : 0, transition: 'all 0.3s ease' }}>
                <Container >
                    <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', paddingLeft: '0px !important', paddingRight: '0px !important', py: 2 }}>
                        <Stack direction="row" spacing={2} >
                            <Box component={"img"} src={Logo} alt="Ozgelecek" sx={{ width: 150, mr: 2, cursor: 'pointer' }} onClick={() => navigate(routes.anonymous.main)} />


                            <Button
                                variant='text'
                                component={RouterLink}
                                to={routes.anonymous.main}
                                color={path === "/" ? "primary" : "inherit"}
                            >
                                Anasayfa
                            </Button>


                            <Button
                                variant='text'
                                component={RouterLink}
                                to={routes.anonymous.whatIsOzgelecek}
                                color={path === routes.anonymous.whatIsOzgelecek ? "primary" : "inherit"}
                            >
                                Özgelecek Nedir?
                            </Button>

                            <Button
                                variant='text'
                                // component={RouterLink}
                                // to={routes.anonymous.findAdvertisement}
                                onClick={() => setOpenDialog(true)}
                                color={path === routes.anonymous.findAdvertisement ? "primary" : "inherit"}
                            >
                                İlan Bul
                            </Button>


                            <Button
                                variant='text'
                                component={RouterLink}
                                to={routes.anonymous.firmaCozumleri}
                                color={path === routes.anonymous.firmaCozumleri ? "primary" : "inherit"}
                            >
                                Firma Çözümleri
                            </Button>

                            <Button
                                variant='text'
                                component={RouterLink}
                                to={routes.anonymous.blog}
                                color={path === routes.anonymous.blog ? "primary" : "inherit"}
                            >
                                Blog
                            </Button>


                            {/* <Button color="inherit">Özgelecek Dönüşüm</Button> */}
                        </Stack>

                        <Stack direction="row" spacing={2}>
                            <Button
                                color="primary"
                                variant='text'
                                onClick={() => {
                                    window.location.href = process.env.REACT_APP_APP_URL ?? ""
                                }}
                            >
                                Giriş Yap
                            </Button>

                            <Button
                                color="primary"
                                variant='contained'
                                onClick={() => {
                                    window.location.href = process.env.REACT_APP_APP_URL + "/kayit-ol" ?? ""
                                }}
                            >
                                Kayıt Ol
                            </Button>
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar> :
                <AppBar position="sticky" sx={{ bgcolor: isSticky ? '#FFFFFFDD' : 'transparent', boxShadow: isSticky ? 5 : 0, transition: 'all 0.3s ease' }}>
                    <Container >
                        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', paddingLeft: '0px !important', paddingRight: '0px !important', py: 2 }}>
                            <IconButton
                                color="primary"
                                aria-label="menu"
                                onClick={handleDrawerOpen}
                                sx={{ width: 40, height: 40 }}
                            >
                                <Menu />
                            </IconButton>
                            <Box component={"img"} src={Logo} alt="Ozgelecek" sx={{ width: 150, mr: 2, cursor: 'pointer' }} onClick={() => navigate(routes.anonymous.main)} />
                            <Box width={40} />
                        </Toolbar>
                    </Container>
                </AppBar>
            }

            {
                path === "/" && (
                    <Grid
                        container
                        spacing={2}
                        bgcolor={'#f8f8fe'}
                        sx={{
                            mt: -11,
                            backgroundImage: `url(${Pattern})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPositionX: 'right'
                        }}
                    >
                        <Grid item xs={12} >
                            <Container maxWidth="lg" sx={{ mt: 10 }}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} md={7}>
                                        {/* <Typography fontSize={40} fontWeight={700} color="primary" mt={5} >Şirketler için
                                    verimli çalışan; </Typography>
                                <Typography fontSize={40} fontWeight={700} color="primary.darker" mb={5} >   Çalışanlar için
                                    hayallerdeki iş!</Typography>
                                <Typography fontSize={15} fontWeight={300} color="text.secondary" mb={5} >Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.</Typography>
                                */}
                                        <Typography fontSize={40} fontWeight={700} color="primary" mt={5} >Burası Özgelecek,</Typography>
                                        <Typography fontSize={40} fontWeight={700} color="primary.darker" mb={5} >Burada İŞLER Bambaşka! </Typography>
                                        <Typography fontSize={15} fontWeight={300} color="text.secondary" mb={5} >Özgelecek vizyonuyla iş ara, geçmiş deneyimlerinle aynı işte kalma, hayallerinin işine kavuş!</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Box
                                                sx={{
                                                    border: '1px solid #d6ddeb',
                                                    bgcolor: 'white',
                                                    p: 2,
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    width: '100%',
                                                }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    name="personalInformation.name"
                                                    // label="Name"
                                                    value={text}
                                                    onChange={(e) => {
                                                        setText(e.target.value)
                                                    }}
                                                    placeholder='Pozisyon, departman vb.'
                                                    variant='outlined'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <SearchRoundedIcon />
                                                        ),
                                                        style: {
                                                            color: '#999',
                                                        }
                                                    }}
                                                    sx={{
                                                        color: '#999',
                                                        py: 1
                                                    }}
                                                />


                                                <FormControl fullWidth>
                                                    {!variables.filterOption?.filter?.city && <FormHelperText sx={{ position: 'absolute', top: 7, left: 30 }}>
                                                        <Typography variant='body2' color={"GrayText"} fontSize={"0.875rem"}>Şehir</Typography>
                                                    </FormHelperText>}
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={variables.filterOption?.filter?.city}
                                                        // label="Şehir seçiniz"
                                                        variant='outlined'
                                                        MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                                                        defaultValue=""
                                                        onChange={(e) => {
                                                            setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                                                                ...prevState,
                                                                filterOption: {
                                                                    ...prevState.filterOption,
                                                                    filter: {
                                                                        ...prevState.filterOption.filter,
                                                                        city: e.target.value
                                                                    }
                                                                }
                                                            }))
                                                        }}

                                                        sx={{
                                                            flex: 1,
                                                            color: '#999',
                                                            // py: 1
                                                        }}

                                                        startAdornment={<LocationOnOutlinedIcon />}


                                                    >
                                                        {
                                                            citiesData?.data?.map((city: any, index: number) => {

                                                                return (
                                                                    <MenuItem value={city?.name} key={index}>
                                                                        {city?.name}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <Button variant='contained' sx={{ width: 250 }} color='primary'
                                                    // onClick={() => navigate(routes.anonymous.findAdvertisement + `?search=${text}&city=${variables.filterOption?.filter?.city}`)}
                                                    onClick={() => {
                                                        setOpenDialog(true)
                                                    }}
                                                >İlan Bul</Button>
                                            </Box >
                                        </Box>
                                        <Typography fontSize={15} fontWeight={300} color="text.secondary" mt={2} mb={2} >Popüler: Arge, Bilgi Teknolojileri, Satış ve Pazarlama </Typography>

                                    </Grid>
                                    {!downMd && <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Box component={"img"} src={OzgelecekMain} alt="Ozgelecek" sx={{ height: '100%' }} />
                                    </Grid>}
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid >
                )
            }


            <Container maxWidth="lg" sx={{ padding: 2 }}>
                <Outlet />
            </Container>

            <Stack sx={{ bgcolor: '#202430', color: 'white', mt: 5, p: 2, width: '100%' }}>
                <Container maxWidth={"lg"} sx={{ mt: 3 }}>

                    <Grid container >
                        <Grid item xs={12} md={5} pr={3}>
                            <Box component={"img"} src={LogoWhite} alt="Ozgelecek" sx={{ width: 150 }} />
                            <Typography fontSize={15} color="white" mt={2} mb={2} >Özgelecek, iş arayanlar ve işverenlerin buluştuğu, işe alım süreçlerini kolaylaştıran, iş dünyasında dönüşüm yaratan bir platformdur.</Typography>
                            {/* Google play ve app store indir butonları */}
                        </Grid>

                        <Grid item xs={12} md={4} display="flex" justifyContent={"center"} gap={10}>
                            <Stack direction="column" >
                                <Typography fontSize={12} fontWeight={700} color="white" mb={2} >
                                    ÖZGELECEK
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.about}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Hakkımızda
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.whatIsOzgelecek}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Özgelecek Nedir?
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.firmaCozumleri}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Şirketler
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    // component={RouterLink}
                                    component="a"
                                    href='https://donusum.ozgelecek.net'
                                    sx={{ textDecoration: 'none' }}
                                // to={routes.anonymous.about}
                                >
                                    Dönüşüm
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.findAdvertisement}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    İlan Bul
                                </Typography>
                            </Stack>

                            <Stack direction="column" >

                                <Typography fontSize={12} fontWeight={700} color="white" mb={2} >&nbsp;</Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.contact}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    İletişim
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.faq}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Sık Sorulan Sorular
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.kvkk}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    KVKK
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.onBilgilendirme}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Çerez Politikaları
                                </Typography>

                                <Typography
                                    fontSize={12}
                                    color="white"
                                    mb={1}
                                    component={RouterLink}
                                    to={routes.anonymous.teslimatveIade}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Teslimat ve İade
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3} display="flex" justifyContent={"flex-end"} gap={10}>
                            <Stack direction="column" gap={2}>
                                <Box component={"img"} src={GooglePlay} alt="Ozgelecek Google Play" sx={{ width: 150 }} />
                                <Box component={"img"} src={AppStore} alt="Ozgelecek App Store" sx={{ width: 150 }} />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 5, mb: 3 }} />

                    <Stack direction={{ xs: "column", md: "row" }} justifyContent="flex-start" alignItems="center" gap={3} px={{ xs: 0, md: 5 }}>
                        <img src={OIB} alt="İşkur OIB" style={{ width: 100 }} />
                        <Typography color={"#626e91"} fontSize={"small"}>Yakın İnsan Kaynakları Yönetim Danışmanlığı A.Ş. (istihdamofisi.net ve ozgelecek.net) 17/01/2022 - 16/01/2025 tarihleri arasında Özel İstihdam Bürosu Olarak faaliyette bulunmak üzere, Türkiye İş Kurumu’nun 11/01/2022 tarih, 10541883 sayılı kararı ve 1333 numaralı belgesi ile yetkilendirilmiştir. 4904 sayılı Kanun uyarınca iş arayanlardan ücret alınmayacak ve menfaat temin edilmeyecektir. Şikayetleriniz için Türkiye İş Kurumu İzmir İl Müdürlüğü: 0232 482 12 48
                        </Typography>
                    </Stack>
                    <Divider sx={{ mt: 5, mb: 3 }} />

                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} justifyContent="space-between" alignItems="center">
                        <Box component={"img"} src={IyzicoImage} alt="Iyzico" sx={{ width: 250, bgcolor: 'white', px: 2, py: 1, }} mb={3} />
                        <Typography fontSize={12} color="white" textAlign="center" mb={3} >© 2024 Özgelecek. Tüm hakları saklıdır.</Typography>
                    </Stack>

                </Container>
            </Stack>

            <SwipeableDrawer open={drawerOpen} anchor="left" onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)}>
                <Box sx={{ width: 220, height: '100%', bgcolor: 'secondary.lighter', p: 2, pt: 5 }}>
                    <Box component={"img"} src={Logo} alt="Ozgelecek" sx={{ width: 150, cursor: 'pointer' }} onClick={() => navigate(routes.anonymous.main)} />
                    <List sx={{ mt: 3 }}>
                        <MenuItem onClick={() => {
                            navigate(routes.anonymous.main)
                            setDrawerOpen(false)
                        }} sx={{ py: 1, fontWeight: "bold" }}>Anasayfa</MenuItem>
                        <MenuItem onClick={() => {
                            navigate(routes.anonymous.whatIsOzgelecek)
                            setDrawerOpen(false)
                        }} sx={{ py: 1, fontWeight: "bold" }}>Özgelecek Nedir?</MenuItem>
                        <MenuItem onClick={() => {
                            navigate(routes.anonymous.findAdvertisement)
                            setDrawerOpen(false)
                        }} sx={{ py: 1, fontWeight: "bold" }}>İlan Bul</MenuItem>
                        <MenuItem onClick={() => {
                            navigate(routes.anonymous.firmaCozumleri)
                            setDrawerOpen(false)
                        }} sx={{ py: 1, fontWeight: "bold" }}>Firma Çözümleri</MenuItem>
                    </List>

                    <Divider />

                    <List>
                        <MenuItem><Button color="primary" variant='outlined' fullWidth onClick={() => window.location.href = process.env.REACT_APP_APP_URL ?? ""}>Giriş Yap</Button></MenuItem>
                        <MenuItem><Button color="primary" variant='contained' fullWidth onClick={() => window.location.href = process.env.REACT_APP_APP_URL + "/kayit-ol" ?? ""}>Kayıt Ol</Button></MenuItem>
                    </List>
                </Box>
            </SwipeableDrawer>


            <Dialog open={openDialog}
                onClose={() => {
                    setOpenDialog(false)
                    setProgress(0)
                }}
            >
                <DialogTitle align='center'>
                    <img src={JobHunt} alt='ilan bul' style={{ width: 200, height: 200 }} />
                </DialogTitle>
                <DialogContent>
                    <Typography textAlign={"center"}>
                        Bu içeriklere erişebilmek için giriş yapmanız gerekmektedir.
                    </Typography>

                    <Typography fontSize={12} color="text.secondary" mt={2} mb={1} textAlign={"center"}>
                        Otomatik şekilde yönlendirileceksiniz.
                    </Typography>

                    <LinearProgress variant="determinate" value={progress} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            setOpenDialog(false)
                            setProgress(0)
                        }}
                        variant='text'
                    >
                        İptal
                    </Button>

                </DialogActions>
            </Dialog>

        </Box >
    )
}
