import { Route } from 'react-router-dom'
import { ReferencerMainPage } from 'pages'
import { routes } from './routes'
import { ReferencerLayout } from 'layouts'



export const referencerRouter = () => {

    return (
        <Route path='/referans' element={<ReferencerLayout />}>
            <Route path="" element={<ReferencerMainPage />} />
            {/* <Route path="*" element={<ReferencerMainPage />} /> */}
        </Route>
    )
}
