import * as yup from 'yup'
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { Grid, Typography, Stack, Card } from '@mui/material';

import { FormikTextField } from 'components';
import { IAnonymousCreateInformationFormVariables, useAnonymous_createInformationFormMutation } from 'context';


export const CreateInformationFormSection = () => {

    const [createInformation, { isLoading: createInformationLoading }] = useAnonymous_createInformationFormMutation()
    const validationSchema = yup.object().shape({
        email: yup.string().required(""),
        name: yup.string().required(""),
        phoneNumber: yup.string().required(""),
        surname: yup.string().required("")
    })

    const initialValues: IAnonymousCreateInformationFormVariables = {
        email: "",
        name: "",
        phoneNumber: "",
        surname: ""
    }

    const handleSubmit = async (values: IAnonymousCreateInformationFormVariables) => {
        try {
            const response: any = await createInformation(values)
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Bilgileriniz gönderildi. En kısa sürede iletişime geçeceğiz.")
            } else {
                enqueueSnackbar("Oluşturulamadı.", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti.", { variant: 'error' })
        }
    }


    return (
        <Card sx={{ p: 3, mt: 5 }}>

            <Typography variant='h3' mb={3} textAlign={"center"} color={"primary.darker"}>
                Bilgi almak için bize bilgilerinizi bırakın.
            </Typography>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log({ values });
                    handleSubmit(values)
                }}
            >
                {
                    ({ handleSubmit }) => {
                        return (
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormikTextField
                                        name="name"
                                        label="İsim"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormikTextField
                                        name="surname"
                                        label="Soyisim"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormikTextField
                                        name="email"
                                        label="Email"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormikTextField
                                        name="phoneNumber"
                                        label="Telefon Numarası"
                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="center">
                                        <LoadingButton
                                            variant='contained'
                                            color="primary"
                                            onClick={() => handleSubmit()}
                                            loading={createInformationLoading}
                                            disabled={createInformationLoading}
                                            sx={{px:10}}
                                        >
                                            Gönder
                                        </LoadingButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        )
                    }
                }
            </Formik>
        </Card>
    )
}
