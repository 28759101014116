import { Grid, Typography, List, ListItem, ListItemText, Box, Divider, Stack, Button, Chip, Pagination, ListItemButton, IconButton } from '@mui/material';
import {
    Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation,
    HandshakeAnimation, SearchAnimation, Donusum, HrDanismanlik, Dart,
} from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { CreateInformationFormSection } from 'sections';
import { useGetAnonymoustBlogPostQuery, useGetAnonymoustBlogPostsQuery } from 'context';
import moment from 'moment';
import { CloseOutlined } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { routes } from 'routes';

const categories = [
    { value: "İşe Alım", name: "İşe Alım" },
    { value: "İş İlanları ", name: "İş İlanları " },
    { value: "Kariyer Gelişimi", name: "Kariyer Gelişimi" },
    { value: "İşe Alım Trendleri ", name: "İşe Alım Trendleri " },
    { value: "Beceri Temelli İşe Alım ", name: "Beceri Temelli İşe Alım " },
    { value: "Beceri Gelişimi", name: "Beceri Gelişimi" },
    { value: "Eğitim", name: "Eğitim" },
    { value: "Teknoloji", name: "Teknoloji" },
    { value: "Sektörel Çalışan İhtiyaçları", name: "Sektörel Çalışan İhtiyaçları" },
    { value: "Çalışan Bağlılığı", name: "Çalışan Bağlılığı" },
    { value: "Kariyer Rehberi", name: "Kariyer Rehberi" },
    { value: "İşletme Rehberi", name: "İşletme Rehberi" },
    { value: "Başarı Hikayeleri", name: "Başarı Hikayeleri" },
    { value: "Özgelecek İpuçları", name: "Özgelecek İpuçları" },
    { value: "Özgelecek İle İşe Alım ", name: "Özgelecek İle İşe Alım " },
    { value: "Girişimcilik ve İnovasyon", name: "Girişimcilik ve İnovasyon" },
    { value: "Çalışma-Yaşam Dengesi", name: "Çalışma-Yaşam Dengesi" },
    { value: "Mülakat Teknikleri", name: "Mülakat Teknikleri" },
    { value: "Yeni Mezun Rehberi", name: "Yeni Mezun Rehberi" },
    { value: "Yapay Zeka", name: "Yapay Zeka" },
    { value: "Mentorluk", name: "Mentorluk" },
    { value: "Meslekler", name: "Meslekler" },
    { value: "Freelance ve Serbest Çalışma", name: "Freelance ve Serbest Çalışma" },
    { value: "Uzaktan Çalışma", name: "Uzaktan Çalışma" },
    { value: "İnsan Kaynakları Trendleri", name: "İnsan Kaynakları Trendleri" },
    { value: "İş Kanunu ve Mevzuat", name: "İş Kanunu ve Mevzuat" },
    { value: "Liderlik", name: "Liderlik" },
    { value: "Genel", name: "Genel" },
    { value: "Sektör İhtiyaçları", name: "Sektör İhtiyaçları" },
    { value: "Çalışma Modelleri", name: "Çalışma Modelleri" },
]

const targetCategories = [
    { value: "jobSeeker", name: "İş Arayanlar" },
    { value: "worker", name: "Çalışanlar" },
    { value: "company", name: "Şirketler" },
    { value: "universityStudent", name: "Üniversite Öğrencileri" },
]


export const BlogDetailPage = () => {



    const { slug } = useParams();

    console.log(slug)

    const { data: blogPost, error: blogPostError, isLoading: blogPostLoading } = useGetAnonymoustBlogPostQuery({
        slug: slug ?? ""
    });
    const { data: blogPosts, error: blogPostsError, isLoading: blogPostsLoading } = useGetAnonymoustBlogPostsQuery({
        pagination: {
            page: 1,
            pageSize: 5
        },
        filter: {
            targetCategory: null,
            category: "",
            language: null
        }
    });




    return <>

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} >

                    <Typography variant="h1" mt={2} textAlign={"center"} color="primary.main">{blogPost?.title}</Typography><br />

                    <Typography variant="overline" color="textSecondary" textAlign={"center"}>{moment(blogPost?.createdAt).format("DD MMMM YYYY")}</Typography>
                </Box>
            </Grid>
        </Grid>

        <Grid container >
            <Grid item xs={12} >
                <img src={blogPost?.imageUrl} alt={blogPost?.title} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
            </Grid>

            <Grid item xs={12} md={8} lg={9} gap={2} display={"flex"} flexDirection={"column"} position={"relative"}
                pr={2} p={2}>
                <Typography variant="body2" color="textSecondary" gutterBottom textAlign={"right"} pr={{ xs: 0, md: 2 }} >
                    Okuma Süresi: {blogPost?.readingTime}
                </Typography>

                <Box pr={{ xs: 0, md: 2 }} >
                    <Typography textAlign="justify">
                        {
                            parse(blogPost?.content ?? "")
                        }
                    </Typography>
                    <Box mt={2} display={"flex"} gap={2} >
                        {blogPost?.categories?.map((category: any, index: any) => (
                            <Chip key={index} label={category} color="primary" size="small" variant='outlined'  />
                        ))}
                    </Box>
                </Box>

            </Grid>
            <Grid item xs={12} md={4} lg={3} gap={2} display={"flex"} flexDirection={"column"} pr={2} border={1} borderColor={"#f0f0f0"} borderRadius={2} p={2} mt={2} >
                <b>En Yeni Bloglar</b>
                <Divider />
                <List>
                    {blogPosts?.data?.map((post: any, index: any) => (
                        <BlogCard {...post} />
                    ))}
                </List>
            </Grid>

        </Grid >

    </ >

}


function BlogCard(props: any) {
    const { title, slug, imageUrl, readingTime } = props;

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                padding: 2,
                overflow: 'hidden',
                textAlign: 'left',
                border: '1px solid #f0f0f0',
                cursor: 'pointer',
                width: '100%',
                mb: 2
            }}
            onClick={() => navigate(routes.anonymous.blogDetail.replace(":slug", slug))}
        >
            <Box width={80} height={60} >
                <img src={imageUrl} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Box>
            <Box pl={2} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: '100%' }}>

                <Typography fontWeight={"bold"} component="div" gutterBottom mt={1}>
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom >
                    Okuma Süresi: {readingTime}
                </Typography>
            </Box>
        </Box>
    );
}