import { Grid, Typography, List, ListItem, ListItemText, Box, Divider } from '@mui/material';
import { Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation, HandshakeAnimation, AboutUsAnimation } from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { Caner, Erdem, Hilal, Percihan, Yigit } from 'assets';
import parse from 'html-react-parser';

const html = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-size:11.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:42.55pt 42.55pt 42.55pt 42.55pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=TR link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-family:"Arial",sans-serif'>ÇEREZ POLİTİKASI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Özgelecek
İnsan Kaynakları Teknolojileri Anonim Şirketi (“ÖZGELECEK A.Ş.”) olarak
internet sitemizde çerez kullanmaktayız. Bu Çerez Politikası (“Politika”)
ÖZGELECEK A.Ş. tarafından yönetilen ozgelecek.net adresli internet sitesi için
geçerli olup, çerezler işbu Politika’da belirtilen şekilde kullanılacaktır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Bu
Politika en son </span><b><span style='font-family:"Arial",sans-serif'>15/02/2024</span></b><span
style='font-family:"Arial",sans-serif'> Tarihinde güncellenmiştir</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZ
TANIMI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
bir internet sitesi ziyaret edildiğinde, internet sitesi tarafından tarayıcınız
aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır. Çerezler
vasıtasıyla kullanıcıların bilgilerinin işleniyor olması sebebiyle, 6698 sayılı
Kişisel Verilerin Korunması Kanunu gereğince, kullanıcıların
bilgilendirilmeleri ve onaylarının alınması gerekmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Ziyaret
ettiğiniz web siteleri, bilgisayarınıza veya telefonunuza küçük bilgiler
kaydedip, daha sonra aynı siteyi ziyaret ettiğinizde</span> <span
style='font-family:"Arial",sans-serif'>bu bilgileri okuyabilirler. Bu küçük
bilgilerin kaydedildiği dosyalara çerez denir. Çerezler genellikle internette
gezinti deneyiminizi iyileştirmeye ve siteleri kişiselleştirmeye yarar. Örneğin
bir siteye giriş yaparken “beni hatırla” seçeneğini işaretlerseniz o site,
kullanıcı adınızı (veya özel bir kimlik numarasını) içeren bir çerezi
tarayıcınıza kaydeder. Çerezleri yalnızca onları oluşturan site okuyabilir.
Çerezin ne kadar süreyle saklanacağını da site belirler.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZLERİN
KULLANIMI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
genellikle site kullanıcısının deneyim kalitesini arttırmak için kullanılır.
Çerezler sayesinde ziyaret edilen internet sitesi tekrar ziyaret edildiğinde,
ziyaretçinin önceki tercihlerini hatırlayabilir. Böylece internet sitesinin
işlevselliğini arttırarak sunulan hizmeti geliştirir. Kullanım amaçlarına göre
farklı çerezler bulunmaktadır. Bunların bazıları zorunlu çerezler, bazıları ise
kullanıcının tercihine bırakılabilir çerezlerdir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
aynı zamanda internet sitesi üzerinden yeni özellikler sunmak ve sunulan
özellikleri iyileştirmek için kullanılmaktadır.</span> <span style='font-family:
"Arial",sans-serif'>Çerezleri kabul etmek, size daha iyi bir kullanıcı deneyimi
sağlar. Zorunlu çerezler dışındaki çerezleri silebilir veya engelleyebilirsiniz
(aktif veya inaktif seçeneği ile tercihlerinizi belirleyebilirsiniz.) fakat bu
halde web sitesi gerektiği gibi çalışmayabilir veya tüm özelliklerinden
yararlanmanız mümkün olmaz. Her zaman tercihlerinizi değiştirebilmeniz
mümkündür. “ozgelecek.net” sitesinde zorunlu çerezler kullanılmaktadır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>ÖZGELECEK
A.Ş.’ye ait “ozgelecek.net” adlı web sitesinden en verimli şekilde faydalanılması
ve kullanıcı deneyimini geliştirebilmek için çerezler kullanılmaktadır. Web
sitesine giriş yapıldığında, çerezlerin kabul edilip edilmediğine ilişkin soru
sorulmaktadır. Çerezler “Kabul Et” seçeneği seçildikten sonra aktif hale
gelecektir. Kullanıcının daha sonra fikrini değiştirmesi halinde ve Çerezleri
kullanmak istememesi halinde, tarayıcı ayarlarından çerezleri her zaman silme
ya da engelleme imkanı bulunmaktadır. Ancak bu durum kullanıcının web sitesi
kullanımını etkileyebilir. Tarayıcılardan çerez ayarları değiştirilmediği
sürece işbu sitede çerez kullanımı devam edecektir. Çerezlerin kullanım amacı
ve sürelerine işbu Politika’nın devamında değinilmiştir. Sitede yer alan
servislerin kullanımı için  doğrudan veya dolaylı olarak bazı bilgi ve veriler
paylaşılmaktadır. Bu bilgiler siteye kayıt olurken verilen kişisel bilgiler
(isim, e-posta adresi, telefon gibi) veya siteyi kullanımı (iletişim formunu
kullanırken, yorum yaparken vb.) esnasında toplanan bilgilerdir (IP, cihaz,
konum ve tarayıcı bilgileri, gezinti ve etkileşim içeriği, tarih ve bağlantı
süresi gibi veriler). Elde edilen bilgi ve verileri saklama konusunda ÖZGELECEK
A.Ş. tarafından azami özen gösterilmektedir. ÖZELECEK A.Ş. “ozgelecek.net”
sitesinin sunucularında depolanan çerez verilerini üçüncü kişiler ile paylaşmaz.
Sitede bulunan harici hizmetler (Canlı Destek eklentisi, sosyal medya
eklentileri vb.) tarafından depolanan çerezler ise harici hizmetlerin
sorumluluğunda olup ÖZGELECEK A.Ş.’nin herhangi bir sorumluluğu yoktur. Daha
detaylı bilgi için; “ozgelecek.net” sitesinden 'KVKK Aydınlatma Metni', ve
'Gizlilik ve Kişisel Verileri Koruma Politikası' incelenmelidir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZLERİN
KULLANIM AMAÇLARI</span></b></p>

<p class=MsoNormal style='margin-left:35.4pt;text-align:justify;text-indent:
-35.4pt'><span style='font-family:"Arial",sans-serif'>Çerezlerin farklı
kullanım amaçları olabilir:</span> </p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Güvenlik
Amaçlı Kullanımlar:</span></b><span style='font-family:"Arial",sans-serif'> “ozgelecek.net”
sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki
fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları tespit eden
çerezler kullanabilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>İşlevselliğe
Yönelik Kullanımlar:</span></b><span style='font-family:"Arial",sans-serif'>
“ozgelecek.net” sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde
kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş
seçimlerini hatırlatan çerezler kullanabilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Performansa
Yönelik Kullanımlar: </span></b><span style='font-family:"Arial",sans-serif'>“ozgelecek.net”,
sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen
iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz
eden çerezler kullanabilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Reklam
Amaçlı Kullanımlar:</span></b><span style='font-family:"Arial",sans-serif'> “ozgelecek.net”
kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi
alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu
reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler
kullanabilmektedir.</span> </p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Web
sitesine yönelik şüpheli eylemlerin tespiti ve gerekli güvenliğin sağlanması,
ziyaretçilerin tercihleri doğrultusunda web sitesinin işlevsellik ve
performansın artırılması, web sitesinde sunulan hizmetlerin ve ürünlerin
geliştirilmesi ve kişiselleştirilmesiyle, bu hizmetlere ve ürünlere ulaşımın
kolaylaştırılması, web sitesinin hukuki sorumluluklarının yerine getirilmesi
amaçlı çerezler kullanmaktadır. Ayrıca, web sitesi ziyaretçilerini daha geniş
kapsamlı hizmet sağlayıcılar ile buluşturulabilmesi amacıyla reklam çerezleri
ve üçüncü taraflarla bilgi paylaşımı da söz konusudur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZ
ÇEŞİTLERİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Zorunlu
Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Zorunlu
çerezler, internet sitesinin çalışması ve amaçlandığı gibi kullanılabilmesi
için kesinlikle gerekli olan çerezlerdir. Zorunlu çerezlerin engellenmesi
halinde, internet sitesinin bazı bölümleri çalışmayacaktır. Bu çerezler,
güvenliği iyileştirme, aramaları kaydetme, oturum açma veya benzer işlevlerin
kullanılmasına imkân tanır ve pazarlama faaliyetleri amacıyla kullanılmazlar.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Teknik
Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Teknik
Çerezler, sitenin olması gerektiği gibi çalışmasını sağlamaktadır.  Sitenin
çalışmayan sayfaları ve alanları bu çerezlerce tespit edilir ve onarımı için
aksiyon alınır. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Otantikasyon
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Ziyaretçilerin
siteye şifrelerini kullanarak giriş yapmaları durumunda otantikasyon çerezleri
site içinde ziyaret devam ettikçe tekrar şifre sorulmamasını sağlar. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>İşlevsel
Çerezler:</span></b><span style='font-family:"Arial",sans-serif'> İşlevsel
çerezler, internet sitesini ziyaret eden kullanıcıların tercihlerine dayalı
olarak site içeriğini onlar için bireyselleştirmeyi sağlar. Bu çerezlerin
kullanımının engellenmesi mümkündür.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Flash
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Sitede yer
alan görüntü ve ses içeriklerinin etkinleştirilmesi için kullanılan çerez
türleridir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Kişiselleştirme
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Kullanıcıların
tercihlerini (ör. Dil Seçeneği) farklı internet sitelerinin farklı sayfaları
için hatırlamasını sağlayan çerezlerdir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Performans
Çerezleri:</span></b><span style='font-family:"Arial",sans-serif'> Performans
çerezleri, web sitesinin geliştirilmesinde yardımcı olur. Kullanıcı deneyimini
iyileştirmek amacıyla, sitenin ne şekilde ne kadar süre kullanıldığı gibi
bilgileri toplar.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Analitik
Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Analitik
çerezler, siteyi ziyaret edenlerin sayılarının belirlenmesi, hangi sayfaların
ziyaret edildiğinin tespiti, sitenin ziyaret edildiği saatler gibi verilerle
ilgili sonuç üretimini sağlayan çerezlerdir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Reklam/Pazarlama
Çerezleri:</span></b><span style='font-family:"Arial",sans-serif'> Reklam
çerezleri, kullanıcıların ilgi alanlarına göre ve onlara uygun içerikler ile
reklamları sunmak (“hedeflenmiş reklam/tanıtım” veya “davranışsal reklamcılık”)
amacıyla kullanılır. Çerezler yoluyla elde edilen bilgileri, aynı kişiye ait
diğer verilerle eşleştirerek, ilgililere daha uygun içerikleri, kişiye özel
kampanya ve ürünleri sunar ve daha önceden istenmediği belirtilen içerik veya
fırsatları bir daha sunmaz. Bu çerezlerin kullanımının engellenmesi mümkündür.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Oturum
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Oturum
çerezi, tarayıcı kapatıldığında sona erer. Bu çerezler, internet sitesinin
kullanıcının tarayıcı kullandığı sürece hareketlerinin tanınmasını sağlar.
Örneğin, sitede dolaşırken kullanıcının alışveriş sepetine eklediği ürünlerin
hatırlanması amacıyla kullanılabilir. Kullanıcının internet bankacılığına veya
web maile giriş yaptığı durumlarda da güvenlik amacıyla kullanılabilir. Bu
çerezler oturum sonuna kadar kullanılır, sonrasında cihazda saklanmazlar.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Kalıcı
Çerezler:</span></b><span style='font-family:"Arial",sans-serif'> Kalıcı
çerezler, uzun süre boyunca cihazda saklanırlar. Kullanıcının sitedeki
tercihlerini hatırlatmak amacıyla kullanılır. Bu çerezler, kullanıcının
cihazının sabit diskinde uzun bir süre için veya süresiz olarak kullanıcı
tarafından silinceye kadar mevcudiyetini korur. Bu çerezlerin, cihaza
yerleştirildiği süre ve çerezin sona erme süresi arasındaki uzunluğu internet
sitesi sahibi belirler. Kullanıcılar, bu çerezleri silebilirler.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Birinci
Taraf Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Birinci
taraf çerezler, ziyaret edilen internet sitesi tarafından oluşturulan
çerezlerdir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Üçüncü
Taraf Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Üçüncü
taraf çerezler, diğer siteler, iş ortakları veya servis sağlayıcıları gibi
üçüncü taraflarca oluşturulur. Bu çerezler genellikle, ziyaret edilen internet
sitesinin diğer sitelere ait resim, reklamcılık, sosyal medya eklentilerini
içermesi halinde söz konusu olur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif;
color:red'>ÖZGELECEK A.Ş.’ye AİT “ozgelecek.net” ADRESLİ İNTERNET SİTESİNDE
KULLANILAN ÇEREZLER VE KULLANIM SÜRELERİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='color:red'><img
width=497 height=186 id="Resim 4"
src="https://i.ibb.co/qMb0gCZ/image001.png"></span><span
style='font-family:"Arial",sans-serif;color:red'>(ÖRNEKTİR)</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
işbu Politika’da belirtilen amaçlar dışında kullanılmamakta olup, tüm ilgili
işlemler Kişisel Verilerin Korunması Kanunu’na (KVKK) uygun olarak
yürütülmektedir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZLERİN
SİLİNMESİ VE/VEYA ENGELLENMESİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezleri
istenildiğinde silinebilir ve/veya engellenebilir. Kullanılan cihazda
hâlihazırda mevcut olan çerezleri silinebilir ve web tarayıcınızı çerezleri
engelleyebilecek şekilde ayarlanabilir. Bu ayarlama kullanılan cihaz ve
internet tarayıcısına göre değişiklik göstermektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Aşağıda
farklı internet tarayıcıları üzerinden çerezlerin kullanılmasını engellemek
için hangi adımların izlenmesi gerektiğine ilişkin bilgiler yer almaktadır:</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Internet Explorer</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Masaüstünü açın ve görev çubuğunda Internet Explorer simgesine dokunun veya
tıklayın. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Araçlar düğmesine ve İnternet seçenekleri'ne dokunun veya tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Gizlilik sekmesine dokunun veya tıklayın, ardından tüm tanımlama bilgilerini
engellemek için Ayarlar'ın altında bulunan kaydırıcıyı yukarıya hareket ettirin
ve Tamam düğmesine dokunun veya tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Microsoft Edge</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Microsoft Edge tarayıcınızın sağ üst köşesinden üç nokta işareti olan bölüme
tıklayın ve Ayarlar bölümüne gelin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Karşınıza gelen yeni pencereden Temizlenecek Öğeleri Seç dedikten sonra
karşınıza gelen pencereden temizlemek istediğiniz bölümleri seçin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Burada birçok bölüm bulunmaktadır. İstediğinizi seçerek temizleme işlemine
başlayabilirsiniz</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Google Chrome</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Bilgisayarınızda Chrome'u açın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Sağ üstte Diğer Ayarlar'ı tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Altta Gelişmiş'i tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>4.
&quot;Gizlilik ve güvenlik&quot;in altında İçerik ayarları'nı tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>5.
Çerezler'i tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>6.
&quot;Tüm çerezler ve site verileri&quot;nin altında Web Sitesi'nin adını
arayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>7.
Sitenin sağındaki Kaldır simgesini tıklayın</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Mozilla Firefox</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Firefox Menü düğmesine tıklayın ve Seçenekler'i seçin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Gizlilik ve Güvenlik panelini seçin ve Geçmiş bölümüne gidin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Firefox ayarını geçmiş için özel ayarları kullansın olarak değiştirin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>4.
Çerezleri göster... düğmesine tıklayın. Çerezler penceresi görünecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>5.
Arama: alanına, çerezlerini silmek istediğiniz sitenin adını yazın. Aramanızla
eşleşen çerezler görüntülenecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>6.
Silmek istediğiniz çerez(ler)i seçip Seçilenleri sil düğmesine tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>7.
Kapat düğmesine tıklayarak Çerezler penceresini kapatın. Sonra da
about:preferences sayfasını kapatın</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Safari</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Safari &gt; Tercihler'i seçin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Gizlilik öğesini tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Web Sitesi Verilerini tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>4.
Bir veya daha fazla web sitesi seçin ve sonra Sil veya Tümünü Sil'e tıklayın. Ziyaret
ettiğiniz web sitelerinin geride bıraktığı tüm çerezleri silmeniz, web sitesini
her ziyaret edişinizde verdiğiniz bilgileri tekrar girmeniz ve web sitesinde
yer alan bazı hizmetleri gerektiği gibim çalışmasını engellediğini unutmayınız.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Yukarıda
bahsi geçenler dışında kullanılan tarayıcılarda ise “Yardım” fonksiyonunu
kullanarak ilgili ayarların yerini bulabilirsiniz.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>GİZLİLİK
POLİTİKASI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Yukarıda
adı geçen çerezlerle toplanan veriler “KVKK Aydınlatma Metni” ve “Gizlilik Ve
Kişisel Verilerin Korunması Politikası” kapsamında korunmaktadır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Toplanan
verilerle ilgili internet sitemizin “Gizlilik ve Kişiler Verilerin Korunması
Politikası” ve “KVKK Aydınlatma Metni”ne ozgelecek.net adresinden
ulaşabilirsiniz. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZ
UYARISI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>İşbu
sitede deneyiminizi iyileştirmek için çerez kullanılmaktadır. İnternet sitemizi
kullanmak için çerez kullanımı zorunludur. Bu bağlamda çerezleri kabul
etmemeniz internet sitemizden yararlanamayacağınız ya da tam olarak olması
gerektiği şekilde yararlanamayacağınız anlamına gelebilir.  Sitede gezinmeye
devam ederek çerez kullanılmasına izin vermiş olmaktasınız. Çerezler ile
toplanan kişisel verileriniz işbu Çerez Politikamızda belirtilen amaçlarla ve
mevzuata uygun olarak kullanılacaktır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>İnternet
sitesini görüntülemek için farklı araçlar (ör. Bilgisayar, cep telefonu ya da
tablet) kullanıyorsanız, bu cihazların her birinde kullandığınız tarayıcının
çerez tercihlerinize uygun olduğundan emin olun. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>İLETİŞİM
BİLGİLERİMİZ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Politika
ile ilgili soru ve görüşlerinizi ÖZGELECEK A.Ş.’ye iletmek için aşağıdaki
adrestebelirtilen iletişim yollarından birini kullanabilirsiniz:</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>E-posta
:</span></b><span style='font-family:"Arial",sans-serif'> _________</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Adres
   :</span></b><span style='font-family:"Arial",sans-serif'> Erzene Mah.
Gençlik Cad. No:11 Bornova/İZMİR</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

</div>

</body>

</html>
`


export const OnBilgilendirmeFormu = () => {




    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Box flex="1" p={2}>
                        {parse(html)}
                    </Box>
                </Grid>
            </Grid>
        </ >
    )

}
