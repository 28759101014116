import {
    Box,
    Stack,
    Select,
    Button,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    Pagination,
    FormControl,
    SwipeableDrawer,
    FormHelperText
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import { routes } from 'routes';
import { StaticAdvertisementBgImage } from 'assets';
import { CandidateAdvertisementCard, FilterAccordionMenu, TFilterAccordionMenuList } from 'components'
import { useGetCitiesQuery, useGetAdvertisementsQuery, IAnonymousAdvertisementsVariables, } from 'context'
import { LanguageList, MutationVariablesPaginationSortList, OzgelecekFieldsList, PlaceOfWorkList, PositionLevelList, WorkTypeList } from 'types'



const initialVariables: IAnonymousAdvertisementsVariables = {
    pagination: {
        page: 1,
        pageSize: 4,
    },
    filterOption: {

        filter: {
            title: null,
            cities: [],
            department: null,
            description: null,
            districts: [],
            field: null,
            language: null,
            militaryServiceRequired: null,
            place: null,
            positionLevel: null,
            requiredAgeInterval: null,
            requiredDriverLicense: null,
            requiredEducationLevel: null,
            requiredLanguages: [],
            requiredSkills: null,
            startDate: null,
            workType: null
        },

        search: {
            all: null,
            cities: [],
            department: null,
            description: null,
            districts: [],
            title: null
        }
    },

}

export const FindAdvertisementPage = () => {


    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const city = searchParams.get('city');
    const field = searchParams.get('field');
    const search = searchParams.get('search');
    const [cityNew, setCityNew] = useState("")


    const [filtersDrawer, setFiltersDrawer] = useState(false)

    const [variables, setVariables] = useState<IAnonymousAdvertisementsVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        },
        filterOption: {

            filter: {
                title: null,
                cities: cityNew ? [cityNew] : city ? [city] : [],
                department: null,
                description: null,
                districts: [],
                field: field ?? null,
                language: null,
                militaryServiceRequired: null,
                place: null,
                positionLevel: null,
                requiredAgeInterval: null,
                requiredDriverLicense: null,
                requiredEducationLevel: null,
                requiredLanguages: [],
                requiredSkills: null,
                startDate: null,
                workType: null
            },

            search: {
                all: search ?? null,
                cities: ["search"] ?? [],
                department: search ?? null,
                description: search ?? null,
                districts: ["search"] ?? [],
                title: search ?? null
            }
        },

    })



    const { data: advertisements, isFetching, refetch } = useGetAdvertisementsQuery({ ...variables })

    const filterMenuList: TFilterAccordionMenuList = [
        {
            title: "Çalışma Türü",
            type: "radio",
            function: (e) => setVariables((prevState) => ({
                ...prevState,
                filterOption: {
                    ...prevState.filterOption,
                    filter: {
                        ...prevState.filterOption.filter,
                        workType: e
                    }
                }
            })),
            items: WorkTypeList.map((item: any) => ({
                title: item?.name,
                value: item.value,
            }))
        },

        {
            title: "Çalışma Yeri",
            type: "radio",
            function: (e) => setVariables((prevState) => ({
                ...prevState,
                filterOption: {
                    ...prevState.filterOption,
                    filter: {
                        ...prevState.filterOption.filter,
                        place: e
                    }
                }
            })),
            items: PlaceOfWorkList.map((item: any) => ({
                title: item?.name,
                value: item.value,
            }))
        },
    ]



    const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    }

    const handleReset = () => {
        setVariables(initialVariables)
        window.location.reload();
    }

    const renderFilters = () => {

        return (
            <Stack
                direction="column"
                gap={1}
                sx={{
                    height: 'auto',
                    width: { md: '250px', sm: '300px' },
                }}
            >

                <TextField
                    select
                    label="Çalışma Alanı"
                    fullWidth
                    defaultValue=""
                    value={variables.filterOption.filter?.field ?? ""}
                    onChange={(e: any) => {

                        setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
                            ...prevState,
                            filterOption: {
                                ...prevState.filterOption,
                                filter: {
                                    ...prevState.filterOption.filter,
                                    field: e.target.value
                                }
                            }
                        }))

                    }}
                >
                    {
                        OzgelecekFieldsList.map((option: any) => (
                            <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField
                    select
                    label="Dil"
                    fullWidth
                    defaultValue=""
                    value={variables.filterOption.filter?.language ?? ""}
                    onChange={(e: any) => {

                        setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
                            ...prevState,
                            filterOption: {
                                ...prevState.filterOption,
                                filter: {
                                    ...prevState.filterOption.filter,
                                    language: e.target.value
                                }
                            }
                        }))

                    }}
                >
                    {
                        LanguageList.map((option: any) => (
                            <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField
                    select
                    label="Pozisyon"
                    fullWidth
                    defaultValue=""
                    value={variables.filterOption.filter?.positionLevel ?? ""}
                    onChange={(e: any) => {

                        setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
                            ...prevState,
                            filterOption: {
                                ...prevState.filterOption,
                                filter: {
                                    ...prevState.filterOption.filter,
                                    positionLevel: e.target.value
                                }
                            }
                        }))

                    }}
                >
                    {
                        PositionLevelList.map((option: any) => (
                            <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <FilterAccordionMenu list={filterMenuList} />

                <Button
                    onClick={handleReset}
                    variant='outlined'
                    color="primary"
                    sx={{
                        mt: 1,
                        width: '100%',
                    }}
                >
                    Filtreyi Sıfırla
                </Button>

            </Stack>
        )
    }


    return (
        <>
            <CandidateAdvertisementsUpperFilters variables={variables} setVariables={setVariables} cityNew={cityNew} setCityNew={setCityNew} />

            <Stack
                flexDirection="row"
                gap={3}
                sx={{ my: 5, }}
            >

                <Box sx={{ display: { xs: 'none', md: 'flex' }, }}>
                    {renderFilters()}
                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    {
                        [1, 2, 3]?.map((item) => {

                            return (
                                <Skeleton
                                    key={item}
                                    variant='rectangular'
                                    sx={{
                                        p: 2,
                                        width: '100%',
                                        height: '150px'
                                    }}
                                />
                            )
                        })
                    }
                </Box>

            </Stack>

            <SwipeableDrawer
                swipeAreaWidth={0}
                anchor="bottom"
                open={filtersDrawer}
                onClose={() => setFiltersDrawer(false)}
                onOpen={() => setFiltersDrawer(true)}

                sx={{
                    '& > *': {
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                    }
                }}
            >
                <Box sx={{ minHeight: 300, bgcolor: '#fff', p: 2 }}>
                    {renderFilters()}
                </Box>
            </SwipeableDrawer>
        </>
    )
}


interface ICandidateAdvertisementsUpperFiltersProps {
    variables: IAnonymousAdvertisementsVariables,
    setVariables: (e: any) => void,
    cityNew: string,
    setCityNew: (e: any) => void
}

const CandidateAdvertisementsUpperFilters = ({ variables, setVariables, cityNew, setCityNew }: ICandidateAdvertisementsUpperFiltersProps) => {

    const [text, setText] = useState("")
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })

    useEffect(() => {
        if (variables?.filterOption?.search?.all) {
            setText(variables?.filterOption?.search?.all)
        }
    }, [variables?.filterOption?.search?.all])

    useEffect(() => {
        if (variables?.filterOption?.filter?.cities) {
            setCityNew(variables?.filterOption?.filter?.cities)
        }
    }, [variables?.filterOption?.filter?.cities, setCityNew])



    const handleClick = () => {
        setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
            ...prevState,
            filterOption: {
                ...prevState.filterOption,
                filter: {
                    ...prevState.filterOption.filter,
                    cities: cityNew,
                },
                search: {
                    ...prevState.filterOption.search,
                    all: text
                }
            }
        }))
    }

    return (
        <Stack
            spacing={3}
            sx={{
                // my: 5,
                background: `url(${StaticAdvertisementBgImage})`,
                p: { xs: 2, sm: 5 },
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >

            <Stack textAlign="center" spacing={3}>
                <Stack direction="row" gap={1} justifyContent="center">
                    <Typography variant='h1' color="primary.main">
                        Hayalindeki
                    </Typography>

                    <Typography variant='h1' color="primary.dark">
                        İşi Bul
                    </Typography>

                </Stack>
                <Typography>
                    Gelecekte çalışmak istediğin işi bulmak için arama yap.
                </Typography>
            </Stack>

            <Box
                sx={{
                    border: '1px solid #d6ddeb',
                    p: 2,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    gap: 4,
                    backgroundColor: '#ffffff'
                }}
            >
                <TextField
                    fullWidth
                    name="personalInformation.name"
                    // label="Name"
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value)
                    }}
                    placeholder='Arama yap'
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                            <SearchRoundedIcon />
                        ),
                        style: {
                            color: '#999',
                        }
                    }}
                    sx={{
                        color: '#999',
                        py: 1
                    }}
                />


                <FormControl fullWidth>

                    {cityNew?.length === 0 && (
                        <FormHelperText sx={{ position: 'absolute', top: 7, left: 30 }}>
                            <Typography variant='body2' color={"GrayText"} fontSize={"0.875rem"}>
                                Şehir seçiniz
                            </Typography>
                        </FormHelperText>
                    )}

                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={variables.filterOption?.filter?.city}
                        value={cityNew}
                        // label="Şehir seçiniz"
                        variant='outlined'
                        MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                        defaultValue=""
                        onChange={(e) => {
                            setCityNew(e.target.value)
                            // setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
                            //     ...prevState,
                            //     filterOption: {
                            //         ...prevState.filterOption,
                            //         filter: {
                            //             ...prevState.filterOption.filter,
                            //             city: e.target.value
                            //         }
                            //     }
                            // }))
                        }}

                        sx={{
                            flex: 1,
                            color: '#999',
                            // py: 1
                        }}

                        startAdornment={<LocationOnOutlinedIcon />}


                    >
                        {
                            citiesData?.data?.map((city: any, index: number) => {

                                return (
                                    <MenuItem value={city?.name} key={index}>
                                        {city?.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>

                <Button variant='contained' sx={{ width: 250 }} color='primary' onClick={handleClick}>İlan Bul</Button>

            </Box >

        </Stack >
    )
}





// ilanlar - eski

// advertisements && advertisements?.count > 0 ? (
//     <>

//         <Stack direction={{ xs: 'row' }} alignItems={{ xs: 'center' }} justifyContent={{ xs: 'space-between' }}>

//             <Typography variant='body2' color='GrayText'>
//                 {advertisements.count} ilan bulundu.
//             </Typography>

//             <TextField
//                 select
//                 label="Sıralama"
//                 sx={{ width: { xs: '200px' } }}
//                 defaultValue="createdAtDesc"
//                 value={variables.pagination.sort ?? "createdAtDesc"}
//                 onChange={(e: any) => {

//                     setVariables((prevState: IAnonymousAdvertisementsVariables) => ({
//                         ...prevState,
//                         pagination: {
//                             ...prevState.pagination,
//                             sort: e.target.value
//                         }
//                     }))

//                 }}
//             >
//                 {
//                     MutationVariablesPaginationSortList.map((option: any) => (
//                         <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
//                             {option.name}
//                         </MenuItem>
//                     ))
//                 }
//             </TextField>
//         </Stack>
//         {
//             advertisements?.data?.map((item: any, index: any) => {

//                 return (
//                     <a href={routes.anonymous.advertisementDetails + "/" + item?._id} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
//                         <CandidateAdvertisementCard
//                             key={index}
//                             advertisement={item}
//                             // onClick={(event) => {
//                             //     event.stopPropagation();
//                             //     // navigate(routes.anonymous.advertisementDetails + "/" + item?._id)
//                             //     window.open(routes.anonymous.advertisementDetails + "/" + item?._id, "_blank")
//                             // }}
//                             onApply={(event: any) => {
//                                 event.stopPropagation();
//                                 window.location.href = process.env.REACT_APP_APP_URL + "/ilanlar/" + item?._id
//                                 // setApplyDialog(item)
//                             }}
//                             onReject={(event: any) => {
//                                 event.stopPropagation();
//                                 // setRejectApplicationDialog(item)
//                             }}
//                         />
//                     </a>
//                 )
//             })
//         }

//         {
//             Math.ceil(advertisements?.count / variables?.pagination?.pageSize) > 1 ? (
//                 <Pagination
//                     count={Math.ceil(advertisements?.count / variables?.pagination?.pageSize)}
//                     color="primary"
//                     page={variables?.pagination?.page}
//                     onChange={handlePageChange}
//                     sx={{
//                         m: 'auto',
//                         my: 5
//                     }}
//                 />
//             ) : null
//         }
//     </>
// ) : (
//     <Box>
//         <Typography variant='body2' color='GrayText'>
//             Görüntülenecek ilan bulunamadı.
//         </Typography>
//     </Box>
// )