import { Grid, Typography, List, ListItem, ListItemText, Box, Divider, Stack, Button, Chip, Pagination, ListItemButton, IconButton } from '@mui/material';
import {
    Pattern2, OzgelecekHome2, AboutAnimation, KariyerAnimation, FutureAnimation, HistoryAnimation,
    HandshakeAnimation, SearchAnimation, Donusum, HrDanismanlik, Dart,
} from 'assets';
import React from 'react';
import Lottie from 'lottie-react';
import { CreateInformationFormSection } from 'sections';
import { useGetAnonymoustBlogPostsQuery } from 'context';
import moment from 'moment';
import { CloseOutlined } from '@mui/icons-material';
import { routes } from 'routes';
import { useNavigate } from 'react-router-dom';

const categories = [
    { value: "İşe Alım", name: "İşe Alım" },
    { value: "İş İlanları ", name: "İş İlanları " },
    { value: "Kariyer Gelişimi", name: "Kariyer Gelişimi" },
    { value: "İşe Alım Trendleri ", name: "İşe Alım Trendleri " },
    { value: "Beceri Temelli İşe Alım ", name: "Beceri Temelli İşe Alım " },
    { value: "Beceri Gelişimi", name: "Beceri Gelişimi" },
    { value: "Eğitim", name: "Eğitim" },
    { value: "Teknoloji", name: "Teknoloji" },
    { value: "Sektörel Çalışan İhtiyaçları", name: "Sektörel Çalışan İhtiyaçları" },
    { value: "Çalışan Bağlılığı", name: "Çalışan Bağlılığı" },
    { value: "Kariyer Rehberi", name: "Kariyer Rehberi" },
    { value: "İşletme Rehberi", name: "İşletme Rehberi" },
    { value: "Başarı Hikayeleri", name: "Başarı Hikayeleri" },
    { value: "Özgelecek İpuçları", name: "Özgelecek İpuçları" },
    { value: "Özgelecek İle İşe Alım ", name: "Özgelecek İle İşe Alım " },
    { value: "Girişimcilik ve İnovasyon", name: "Girişimcilik ve İnovasyon" },
    { value: "Çalışma-Yaşam Dengesi", name: "Çalışma-Yaşam Dengesi" },
    { value: "Mülakat Teknikleri", name: "Mülakat Teknikleri" },
    { value: "Yeni Mezun Rehberi", name: "Yeni Mezun Rehberi" },
    { value: "Yapay Zeka", name: "Yapay Zeka" },
    { value: "Mentorluk", name: "Mentorluk" },
    { value: "Meslekler", name: "Meslekler" },
    { value: "Freelance ve Serbest Çalışma", name: "Freelance ve Serbest Çalışma" },
    { value: "Uzaktan Çalışma", name: "Uzaktan Çalışma" },
    { value: "İnsan Kaynakları Trendleri", name: "İnsan Kaynakları Trendleri" },
    { value: "İş Kanunu ve Mevzuat", name: "İş Kanunu ve Mevzuat" },
    { value: "Liderlik", name: "Liderlik" },
    { value: "Genel", name: "Genel" },
    { value: "Sektör İhtiyaçları", name: "Sektör İhtiyaçları" },
    { value: "Çalışma Modelleri", name: "Çalışma Modelleri" },
]

const targetCategories = [
    { value: "jobSeeker", name: "İş Arayanlar" },
    { value: "worker", name: "Çalışanlar" },
    { value: "company", name: "Şirketler" },
    { value: "universityStudent", name: "Üniversite Öğrencileri" },
]


export const BlogPage = () => {


    const [variables, setVariables] = React.useState({
        pagination: {
            page: 1,
            pageSize: 10
        },
        filter: {
            targetCategory: null,
            category: "",
            language: null
        }
    })

    const { data: blogPosts, error: blogPostsError, isLoading: blogPostsLoading } = useGetAnonymoustBlogPostsQuery(variables);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setVariables({
            ...variables,
            pagination: {
                ...variables.pagination,
                page: value
            }
        })
    }


    return <>

        <Grid container >
            <Grid item xs={12} >
                <Box flex="1" p={2}>
                    <Typography variant="h1" mt={2} textAlign={"center"} color="primary.main">Blog</Typography>
                    <Typography variant="body1" textAlign={"center"} mt={2} mb={5}>
                        Özgelecek blog sayfasında, insan kaynakları, işe alım süreçleri, işe alım teknikleri, işe alım mülakatları gibi konularda en güncel ve en doğru bilgileri bulabilirsiniz.
                    </Typography>
                </Box>
            </Grid>
        </Grid>

        <Grid container >
            <Grid item xs={12} md={8} lg={9} gap={2} display={"flex"} flexDirection={"column"} pr={2} >
                {
                    blogPosts?.data?.map((blog: any, index: any) => (
                        <BlogCard
                            key={index}
                            readingTime={blog?.readingTime}
                            title={blog?.title}
                            image={blog?.imageUrl}
                            content={blog?.content}
                            date={moment(blog?.createdAt).fromNow()}
                            categories={blog?.categories}
                            slug={blog?.slug}
                        />
                    ))
                }

                {
                    Math.ceil(blogPosts ? (blogPosts?.count / variables?.pagination?.pageSize) : 0) > 1 ? (
                        <Pagination
                            count={Math.ceil(blogPosts ? (blogPosts?.count / variables?.pagination?.pageSize) : 0)}
                            color="primary"
                            page={variables?.pagination?.page}
                            onChange={handlePageChange}
                            sx={{
                                m: 'auto',
                                my: 5
                            }}
                        />
                    ) : null
                }
            </Grid>
            <Grid item xs={12} md={4} lg={3} gap={2} display={"flex"} flexDirection={"column"} pr={2} border={1} borderColor={"#f0f0f0"} borderRadius={2} p={2} >
                <b>Kategoriler</b>
                <Divider />
                <List>
                    {
                        categories.map((category, index) => (
                            <ListItemButton key={index}
                                sx={{
                                    bgcolor: category.name === variables.filter.category ? "#f0f0f0" : "transparent"
                                }}
                                onClick={() => {
                                    setVariables({
                                        ...variables,
                                        filter: {
                                            ...variables.filter,
                                            category: category.name ?? ""
                                        }
                                    })
                                }}>
                                <ListItemText primary={category.name} />
                                {category.name === variables.filter.category ? <IconButton size='small' color='primary' sx={{ padding: 0 }}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setVariables({
                                            ...variables,
                                            filter: {
                                                ...variables.filter,
                                                category: ""
                                            }
                                        })
                                    }}>
                                    <CloseOutlined />
                                </IconButton> : null}
                            </ListItemButton>
                        ))
                    }
                </List>
            </Grid>

            {/* <Grid item xs={12} md={4} lg={3} gap={2} display={"flex"} flexDirection={"column"} pr={2} border={1} borderColor={"#f0f0f0"} borderRadius={2} p={2} >
                <b>Hedef Kitle</b>
                <Divider />
                <List>
                    {
                        targetCategories.map((targetCategory, index) => (
                            <ListItemButton key={index}
                                sx={{
                                    bgcolor: targetCategory.value === variables.filter.targetCategory ? "#f0f0f0" : "transparent"
                                }}
                                onClick={() => {
                                    setVariables({
                                        ...variables,
                                        filter: {
                                            ...variables.filter,
                                            targetCategory: targetCategory ? targetCategory.value ?? null : null
                                        }
                                    })
                                }}>
                                <ListItemText primary={targetCategory.name} />
                                {targetCategory.name === variables.filter.targetCategory ? <IconButton size='small' color='primary' sx={{ padding: 0 }}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setVariables({
                                            ...variables,
                                            filter: {
                                                ...variables.filter,
                                                targetCategory: null
                                            }
                                        })
                                    }}>
                                    <CloseOutlined />
                                </IconButton> : null}
                            </ListItemButton>
                        ))
                    }
                </List>
            </Grid> */}

        </Grid >

    </ >

}


function BlogCard(props: any) {
    const { title, content, date, readingTime, image, slug } = props;
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                padding: 2,
                overflow: 'hidden',
                textAlign: 'left',
                border: '1px solid #f0f0f0',
                cursor: 'pointer',
                transition: '0.3s',
                '&:hover': {
                    boxShadow: 5,
                },
            }}
            onClick={() => {
                navigate(routes.anonymous.blogDetail.replace(":slug", slug))
            }}
        >
            <Box width={300} height={150} >
                <img src={image} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Box>
            <Box pl={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: '100%' }}>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                    {/* <Typography variant="overline" color="textSecondary">
                        {categories?.map((category: any, index: any) => (
                            <Chip key={index} label={category} color="primary" size="small" variant='outlined' style={{ marginRight: 5 }} />
                        ))}
                    </Typography> */}
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {date}
                    </Typography>
                </Stack>
                <Typography variant="h6" component="div" gutterBottom mt={1}>
                    {title}
                </Typography>

                <Typography variant="body2" component="p">
                    {new DOMParser().parseFromString(content, 'text/html').body.textContent?.substring(0, 200) + "..."}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom mt={1}>
                    Okuma Süresi: {readingTime}
                </Typography>
            </Box>
        </Box >
    );
}