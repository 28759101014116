import React from 'react';
import { Assignment, AssignmentInd, Campaign, CastForEducation, Code, Engineering, GppMaybe, Groups2, LocalFireDepartment, ModeOfTravel, Money, PlayCircleOutline, School, SettingsSuggest, ThumbUpAlt, TrendingUp, Whatshot } from '@mui/icons-material'
import { Grid, Typography, Box, Button, Stack, Avatar, Rating, Chip, Divider, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material'
import { DashboardOrnek, JobHunt, LogoCrop, OzgelecekHome2, Pattern2, Rectangle } from 'assets'
import { MainLayout } from 'layouts'
import Slider from 'react-slick'
import { useGetAdvertisementsQuery, useGetRandomAdvertisementsQuery, useGetAnonymoustBlogPostsQuery } from 'context';
import { OzgelecekFieldEnum, WorkTypeEnums, getNameOfEnum } from 'types';
import moment from 'moment';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { routes } from 'routes';
import { progress } from 'framer-motion';

const testimonials = [
    {
        label: 'Ahmet D.',
        image: 'https://randomuser.me/api/portraits/men/1.jpg',
        description: 'Özgelecek sistemi ile özgeçmişleri geride bırakıp özgeleceğimizle; gelecekteki kendimizle tanışmış oluyoruz. Çok güzel düşünülmüş, tebrikler! ',
    },
    {
        label: 'Ceren A. ',
        image: 'https://randomuser.me/api/portraits/women/1.jpg',
        description: 'Yapmak istediğim işi daha doğrusu hayal ettiğim işi Özgelecek ile buldum! Çünkü geçmişimle değil “ne yapmak istediğimle” ilgilendiler! ',
    },
    {
        label: 'Semra D.',
        image: 'https://randomuser.me/api/portraits/women/3.jpg',
        description: 'Tecrübeye sıkışmışlığın çözüldüğü yer. Gelecekteki Semra ile tanışmış ve çok istediğim işe kavuşmuş oldum. Tüm ekibi tebrik ederim. ',
    },
    {
        label: 'Kağan K.',
        image: 'https://randomuser.me/api/portraits/men/9.jpg',
        description: 'Yeni mezun olarak sürekli tecrübe isteniliyordu. Yeteneklerimi ortaya çıkarabilmek için başvurmadığım yol kalmamıştı; ta ki Özgelecek’e kadar! Sadece yeteneklerim, isteklerim konuşuldu ve istediğim işi almış oldum! ',
    },
    {
        label: 'Oğuzhan Y.',
        image: 'https://randomuser.me/api/portraits/men/6.jpg',
        description: 'İş değiştirmem gerektiğini hissediyordum ancak Özgelecek beni atağa geçirdi. Mesleki mahkumiyetim sona erdi; ruhum ve yapımla örtüştüğü işe sonunda kavuştum! Teşekkürler.',
    },
    {
        label: 'Aygün A.',
        image: 'https://randomuser.me/api/portraits/women/11.jpg',
        description: 'İlk başta anlamadığım; anladığım zaman da hayatımı değiştiren uygulama: Özgelecek! İlgi ve motivasyon kavramları üzerine inşaa edilmiş mükemmel bir uygulama. ',
    },
    {
        label: 'Sema O.',
        image: 'https://randomuser.me/api/portraits/women/10.jpg',
        description: 'Her zaman söylediğimiz şey sistemli hale gelmiş. Gerçekten de biz çalışanlar; motivasyonumuz yüksek olan işlerde başarı gösteriyoruz. Bize bu yolu doğrudan açtığı için Özgelecek ekibine çok teşekkür ediyorum.',
    },
];

function TestimonialCard(props: { testimonial: any }) {
    const { testimonial } = props;

    // Return the card component
    return (
        <Box
            sx={{
                width: { xs: '100%', md: 300 },
                height: 270,
                borderRadius: 1,
                backgroundColor: 'background.paper',
                boxShadow: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'primary.lighter',
                p: 2,
                my: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Avatar src={testimonial.image} alt={testimonial.label} />
            <Typography variant="subtitle2" component="div">
                {testimonial.label}
            </Typography>
            <Typography variant="body2" component="div" height={130}>
                {testimonial.description}
            </Typography>
        </Box>
    );
}

export const HomePage = () => {

    const navigate = useNavigate()
    const { data: vitrinIlanlar, error, isLoading } = useGetRandomAdvertisementsQuery({ size: 8 })
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    const [progress, setProgress] = React.useState(0);

    const { data: yeniIlanlar, error: yeniIlanlarError, isLoading: yeniIlanlarLoading } = useGetAdvertisementsQuery({
        "filterOption": {
            "filter": {
                "extraProducts": null
            }
        },
        pagination: {
            page: 1,
            pageSize: 4,
            sort: "createdAtDesc" as any
        }
    })

    const { data: blogPosts, error: blogPostsError, isLoading: blogPostsLoading } = useGetAnonymoustBlogPostsQuery({
        pagination: {
            page: 1,
            pageSize: 3
        },
        filter: {
            targetCategory: null,
            category: null,
            language: null
        }
    })


    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '60px',
        pauseOnHover: false,
        slidesToShow: 3,
        speed: 500,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
            },
        ],
    };

    console.log(vitrinIlanlar, "vitrinIlanlar")

    React.useEffect(() => {
        if (openDialog) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 200);
            return () => {
                clearInterval(timer);
            };
        }
    }, [openDialog]);

    React.useEffect(() => {
        if (progress === 100) {
            window.open(process.env.REACT_APP_APP_URL + "/ilanlar")
            setOpenDialog(false)
            setProgress(0)
        }
    }, [progress]);


    return (
        <Stack>
            {/* Section 0  */}
            <Typography variant="h2" mt={4} mb={3} color="primary.main">
                Nasıl Kullanılır?
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.main', padding: '20px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        transition: 'box-shadow 0.3s',
                        '&:hover': {
                            boxShadow: '0 0 10px 0 #5344d266'
                        }
                    }}
                        onClick={() => {
                            window.location.href = process.env.REACT_APP_APP_URL + "/kayit-ol" ?? ""
                        }}
                    >
                        <AssignmentInd sx={{ fontSize: 45 }} color='primary' />
                        <Typography variant="h5" color={"primary.main"}>1 Dakikada Özgeleceğinizi Oluşturun</Typography>
                        <Typography variant="body2" pt={2} pb={1} height={50} >Kendi özgeleceğinizi oluşturun ve uygunluk durumunuzu öğrenin.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.main', padding: '25px',
                        marginTop: '-5px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        transition: 'box-shadow 0.3s',
                        '&:hover': {
                            boxShadow: '0 0 10px 0 #5344d266'
                        }
                    }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement)
                            setOpenDialog(true)
                        }}>
                        <Campaign sx={{ fontSize: 50 }} color='primary' />
                        <Typography variant="h5" color={"primary.main"}>İlanlara Başvurun<br /> &nbsp;</Typography>
                        <Typography variant="body2" pt={2} pb={1} height={50} >İlanları araştırın ve özgeleceğinize uygun ilanlara başvurun.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.main', padding: '20px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        transition: 'box-shadow 0.3s',
                        '&:hover': {
                            boxShadow: '0 0 10px 0 #5344d266'
                        }
                    }}
                        onClick={() => {
                            window.location.href = process.env.REACT_APP_APP_URL ?? ""
                        }}
                    >
                        <ThumbUpAlt sx={{ fontSize: 45 }} color='primary' />
                        <Typography variant="h5" color={"primary.main"}>Yeni Kariyerinize Başlayın<br />&nbsp;</Typography>
                        <Typography variant="body2" pt={2} pb={1} height={50} >İş görüşmelerine katılın ve yeni kariyerinize başlayın.</Typography>
                    </Box>
                </Grid>
            </Grid>

            {/* Section 1 */}
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} my={4}>
                    <Typography variant="h2" mt={4} mb={2} color="primary.main">
                        Kullanıcılar Ne Diyor?
                    </Typography>

                    <Slider {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <TestimonialCard key={index} testimonial={testimonial} />
                        ))}
                    </Slider>
                </Grid>
            </Grid>

            {/* Section 2  */}
            <Grid container spacing={2} mt={3}>
                <Grid item xs={12} my={4}>
                    <Box
                        sx={{
                            backgroundImage: `url(${Pattern2})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right',
                            padding: '30px',
                            bgcolor: '#f8f8fe'
                        }}
                    >
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography variant="h2" color="primary.main">
                                Vitrin İlanlar / Öne Çıkan İlanlar
                            </Typography>

                            <Button
                                variant="text"
                                color="primary"
                                size="large"
                                // onClick={() => navigate(routes.anonymous.findAdvertisement)}
                                onClick={() => setOpenDialog(true)}
                            >
                                Tüm İlanları Gör
                            </Button>
                        </Stack>

                        <Grid container spacing={2} mt={4}>

                            {vitrinIlanlar?.map((item, index) => (
                                <Grid item xs={12} md={3} key={index}>
                                    <a href={routes.anonymous.advertisementDetails + "/" + item?._id} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                                height: '100%',
                                                border: '2px solid #d6ddeb',
                                                p: 3,
                                                backgroundColor: '#ffffffdd',
                                                transition: 'all 0.3s ease',
                                                cursor: 'pointer',
                                                ":hover": {
                                                    boxShadow: '0 0 10px 0 #d6ddeb',
                                                },
                                            }}
                                        // onClick={() => window.open(routes.anonymous.advertisementDetails + "/" + item?._id, "_blank")}
                                        >

                                            {item?.extraProducts?.includes("emergent") &&
                                                <Box position={"absolute"} top={10} right={10} borderRadius={"20px"} bgcolor={"primary.lighter"} sx={{ boxShadow: 2 }}>
                                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} p={1}>
                                                        <Typography fontSize={"small"} fontWeight={"bold"} color="primary.dark">
                                                            Acil
                                                        </Typography>
                                                        <LocalFireDepartment sx={{ ml: 0.2, fontSize: '18px', color: "primary.dark" }} />
                                                    </Stack>
                                                </Box>}
                                            <Box
                                                component="img"
                                                src={item?.createdBy?.companyInformation?.profileImage ?? LogoCrop}
                                                sx={{
                                                    width: '100px',
                                                    height: '100px',
                                                    border: '2px solid #d6ddeb',
                                                    borderRadius: '50px',
                                                    objectFit: 'contain',
                                                    backgroundColor: '#FFF'
                                                }}
                                            />
                                            <Typography variant='subtitle1' textAlign={"center"} color={'primary.darker'}>
                                                {item?.title}
                                            </Typography>
                                            <Typography variant='body1'>
                                                <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                    {getNameOfEnum(OzgelecekFieldEnum, item?.field)}
                                                </span>
                                            </Typography>
                                            <Typography variant='body1'>
                                                {item?.locations?.map((location: any, index: any) => (
                                                    <span key={index}>
                                                        {location.city} - {location.districts.join(", ")} &nbsp;
                                                    </span>
                                                ))}
                                            </Typography>
                                            <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
                                                <Chip variant="outlined" label={getNameOfEnum(WorkTypeEnums, item?.workType)}
                                                    color="secondary" style={{ borderRadius: 100 }} sx={{ color: 'primary.main' }} />
                                                <Chip variant="outlined" label={item.department} color="secondary" style={{ borderRadius: 100 }} sx={{ color: 'primary.main' }} />
                                            </Stack>
                                        </Box>
                                    </a>
                                </Grid>
                            ))}
                        </Grid>

                    </Box>
                </Grid>
            </Grid>

            {/* Section 3  */}
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} my={4}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="h2" color="primary.main">
                            En Yeni İlanlar
                        </Typography>
                        <Button
                            variant="text"
                            color="primary"
                            size="large"
                            // onClick={() => navigate(routes.anonymous.findAdvertisement)}
                            onClick={() => setOpenDialog(true)}
                        >
                            Tüm İlanları Gör
                        </Button>
                    </Stack>

                    <Grid container spacing={2} mt={4}>

                        {yeniIlanlar?.data?.map((item, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        gap: 1,
                                        border: '2px solid #d6ddeb',
                                        p: 3,
                                        backgroundColor: '#ffffffdd',
                                        transition: 'all 0.3s ease',
                                        cursor: 'pointer',
                                        ":hover": {
                                            boxShadow: '0 0 10px 0 #d6ddeb',
                                        },
                                        position: 'relative'
                                    }}
                                    onClick={() => navigate(routes.anonymous.advertisementDetails + "/" + item?._id)}
                                >
                                    <Box
                                        component="img"
                                        src={item?.createdBy?.companyInformation?.profileImage ?? LogoCrop}
                                        sx={{
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '30px',
                                            border: '2px solid #d6ddeb',
                                            objectFit: 'contain',
                                            backgroundColor: '#fff'
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                                    <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} >
                                        <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"}>
                                            <Typography variant='subtitle1'>
                                                {item?.title}
                                            </Typography>
                                            {item?.extraProducts?.includes("emergent") &&
                                                <Box ml={1} borderRadius={"20px"} bgcolor={"primary.lighter"} sx={{ boxShadow: 2 }}>
                                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} p={1}>
                                                        <Typography fontSize={"small"} fontWeight={"bold"} color="primary.dark">
                                                            Acil
                                                        </Typography>
                                                        <LocalFireDepartment sx={{ ml: 0.2, fontSize: '18px', color: "primary.dark" }} />
                                                    </Stack>
                                                </Box>}
                                        </Stack>
                                        <Typography fontSize={"small"} color={"GrayText"}>
                                            <span style={{ color: '#888', fontWeight: 'bold' }}>
                                                {getNameOfEnum(OzgelecekFieldEnum, item?.field)}
                                            </span> ·&nbsp;
                                            {item?.locations?.map((location: any, index: any) => (
                                                <span key={index}>
                                                    {location.city} - {location.districts.join(", ")} &nbsp;
                                                </span>
                                            ))}
                                        </Typography>
                                        <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
                                            <Chip size='small' variant="outlined" label={getNameOfEnum(WorkTypeEnums, item?.workType)}
                                                color="secondary" style={{ borderRadius: 100 }} sx={{ color: 'primary.main' }} />
                                            <Chip size='small' variant="outlined" label={item.department}
                                                color="success" style={{ borderRadius: 100 }} sx={{ color: 'success' }} />
                                        </Stack>
                                    </Stack>

                                    <Typography fontSize={"small"} color={"GrayText"} sx={{ position: 'absolute', top: 20, right: 20 }}>

                                        {moment(item?.createdAt).fromNow()}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            {/* Section 4 */}
            <Grid container spacing={2} mt={3}>
                <Grid item xs={12}>
                    <Box bgcolor={"primary.main"} py={0} px={10} position={"relative"}>

                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            width={0}
                            height={0}
                            sx={{
                                borderStyle: 'solid',
                                borderWidth: "0 0 100px 200px",
                                borderColor: "transparent transparent transparent white"
                            }}
                        />

                        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }} alignItems="center" justifyContent="space-between">
                            {/* <Box component="img" src={Rectangle} alt="Rectangle" width="100%" height="auto" /> */}

                            <Stack direction="column" alignItems="flex-start" justifyContent="center" >
                                <Typography
                                    pt={10}
                                    fontSize={{ xs: 25, sm: 35, md: 45 }}
                                    fontWeight={700}
                                    color="white"
                                    zIndex={2}
                                >
                                    Hemen<br /> ilanlara başvurun!
                                </Typography>

                                <Typography
                                    fontSize={16}
                                    color="white"
                                    zIndex={2}
                                    my={{ xs: 0, sm: 1, md: 2 }}
                                >
                                    Özgeleceğinizi oluşturun ve uygun ilanlara başvurun.
                                </Typography>

                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        mb: 8,
                                        bgcolor: 'white', color: 'primary.main',
                                        '&:hover': {
                                            bgcolor: '#ccc',
                                            color: 'primary.dark'
                                        }
                                    }}
                                    onClick={() => {
                                        window.location.href = process.env.REACT_APP_APP_URL + "/kayit-ol" ?? ""
                                    }}
                                >
                                    Ücretsiz Kayıt Ol
                                </Button>

                            </Stack>

                            <Stack direction="column" alignItems="flex-end" justifyContent="flex-end" sx={{ backgroundColor: 'red' }}>
                                <Box
                                    component={"img"}
                                    zIndex={0}
                                    position={"absolute"}
                                    bottom={0}
                                    right={50}
                                    width={{ xs: 0, md: 400, lg: 500 }}
                                    src={DashboardOrnek}
                                    alt="Dashboard Örnek"
                                />
                                <Box
                                    position="absolute"
                                    bottom={0}
                                    right={0}
                                    width={0}
                                    height={0}
                                    zIndex={10}
                                    sx={{
                                        borderStyle: 'solid',
                                        borderWidth: "0 0 100px 200px",
                                        borderColor: "transparent transparent white transparent"
                                    }}
                                />
                            </Stack>

                        </Stack>

                    </Box>
                </Grid>
            </Grid>


            {/* Section 5  */}
            <Typography variant="h2" my={4} mt={10} color="primary.main">
                Popüler Kategoriler ve Şehirler
            </Typography>

            <Grid container spacing={2}>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=arge')
                            setOpenDialog(true)
                        }}
                    >
                        <SettingsSuggest sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Arge</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.light', padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: 'white',
                            bgcolor: '#5344d2',
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: 'transparent',
                                color: 'primary.main'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=bilgiTeknolojileriVeIT')
                            setOpenDialog(true)
                        }}
                    >
                        <Code sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Bilgi Teknolojileri ve IT</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=egitim')
                            setOpenDialog(true)
                        }}
                    >
                        <School sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Eğitim</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=insanKaynaklari')
                            setOpenDialog(true)
                        }}
                    >
                        <Groups2 sx={{ fontSize: 45 }} />
                        <Typography variant="h5">İnsan Kaynakları</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=muhasebeVeFinans')
                            setOpenDialog(true)
                        }}
                    >
                        <Money sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Muhasebe ve Finans</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=muhendislik')
                            setOpenDialog(true)
                        }}
                    >
                        <Engineering sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Mühendislik</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=satisVePazarlama')
                            setOpenDialog(true)
                        }}
                    >
                        <TrendingUp sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Satış ve Pazarlama</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '20px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?field=turizm')
                            setOpenDialog(true)
                        }}
                    >
                        <ModeOfTravel sx={{ fontSize: 45 }} />
                        <Typography variant="h5">Turizm</Typography>
                    </Box>
                </Grid>

            </Grid>

            <Grid container spacing={2} mt={1}>

                <Grid item xs={6} md={2}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'primary.light',
                            padding: '15px',
                            boxShadow: '0 0 0 0 #5344d266',
                            cursor: 'pointer',
                            color: "primary.main",
                            transition: '0.9s',
                            '&:hover': {
                                bgcolor: '#5344d2',
                                color: 'white'
                            }
                        }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?city=Istanbul')
                            setOpenDialog(true)
                        }}
                    >
                        <Typography variant="body1">İstanbul</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} md={2}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.light', padding: '15px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        color: "primary.main",
                        transition: '0.9s',
                        '&:hover': {
                            bgcolor: '#5344d2',
                            color: 'white'
                        }
                    }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?city=Ankara')
                            setOpenDialog(true)
                        }}
                    >
                        <Typography variant="body1">Ankara</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} md={2}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.light', padding: '15px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        color: "primary.main",
                        transition: '0.9s',
                        '&:hover': {
                            bgcolor: '#5344d2',
                            color: 'white'
                        }
                    }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?city=Izmir')
                            setOpenDialog(true)
                        }}
                    >
                        <Typography variant="body1">İzmir</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} md={2}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.light', padding: '15px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        color: "primary.main",
                        transition: '0.9s',
                        '&:hover': {
                            bgcolor: '#5344d2',
                            color: 'white'
                        }
                    }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?city=Kocaeli')
                            setOpenDialog(true)
                        }}
                    >
                        <Typography variant="body1">Kocaeli</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} md={2}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.light', padding: '15px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        color: "primary.main",
                        transition: '0.9s',
                        '&:hover': {
                            bgcolor: '#5344d2',
                            color: 'white'
                        }
                    }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?city=Antalya')
                            setOpenDialog(true)
                        }}
                    >
                        <Typography variant="body1">Antalya</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} md={2}>
                    <Box sx={{
                        textAlign: 'center', borderWidth: 1, borderStyle: 'solid', borderColor: 'primary.light', padding: '15px',
                        boxShadow: '0 0 0 0 #5344d266',
                        cursor: 'pointer',
                        color: "primary.main",
                        transition: '0.9s',
                        '&:hover': {
                            bgcolor: '#5344d2',
                            color: 'white'
                        }
                    }}
                        onClick={() => {
                            // navigate(routes.anonymous.findAdvertisement + '?city=Bursa')
                            setOpenDialog(true)
                        }}
                    >
                        <Typography variant="body1">Bursa</Typography>
                    </Box>
                </Grid>

            </Grid>


            {/* Section 6  */}
            <Grid
                container
                spacing={2}
                mt={5}
                bgcolor={"#f8f8fe"}
                sx={{
                    backgroundImage: `url(${Pattern2})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                }}
            >
                <Grid item xs={12} md={6} >
                    <Box flex="1" p={2}>
                        <Typography variant="h2" mt={2} color="primary.main">
                            Kariyerini Özgelecek Vizyonuyla Planla!
                        </Typography>

                        <Typography variant="body1" mt={2} textAlign={"justify"}>
                            Özgelecek ile kariyer planlama yapmak oldukça kolay ve etkili bir süreçtir.<br /><br />
                            İlk adım olarak, kullanıcılar profil oluştururlar ve Özgelecek’e üye olma adımlarını tamamlarlar. Ardından hayallerindeki işe veya çalışmak için ilgi duyduğu, çalışma motivasyonu yüksek olan ilgili işe başvuru yapmak için söz konusu alanla ilgili olan Alan Testini ve Motivasyon Testini çözerler.<br /><br />
                            Ardından, yine alanla ilgili iki sorudan oluşan Özgelecek sorularını; dilerse metin/yazı ile dilerse video ile yanıtlayarak sisteme kaydederler. Bu sayede işe olan ilgilerini, motivasyonlarını işverenlere doğrudan göstererek çalışma fırsatını yakalamış olurlar.
                        </Typography>
                    </Box>

                    <Box flex="1" p={2}>
                        <List>
                            <ListItem
                                sx={{
                                    p: 2,
                                    border: '1px solid',
                                    borderColor: 'primary.light',
                                    bgcolor: '#ffffff99',
                                    my: 1,
                                    cursor: 'pointer',
                                    transition: '0.9s',
                                    color: 'primary.main',
                                    '&:hover': {
                                        boxShadow: '0 0 30px 10px #d6ddeb'
                                    },
                                    '&:visited': {
                                        color: 'primary.main'
                                    }
                                }}
                                component={RouterLink}
                                to={routes.anonymous.whatIsOzgelecek + "#section1"}
                            >
                                <ListItemText primary="Özgelecek İle Kariyer Planlama Nasıl Yapılıyor?" />
                            </ListItem>

                            <ListItem
                                sx={{
                                    p: 2,
                                    border: '1px solid',
                                    borderColor: 'primary.light',
                                    bgcolor: '#ffffff99',
                                    my: 1,
                                    cursor: 'pointer',
                                    color: 'primary.main',
                                    transition: '0.9s',
                                    '&:hover': {
                                        boxShadow: '0 10px 30px 10px #d6ddeb'
                                    },
                                    '&:visited': {
                                        color: 'primary.main'
                                    }
                                }}
                                component={RouterLink}
                                to={routes.anonymous.whatIsOzgelecek + "#section2"}
                            >
                                <ListItemText primary="Geleceğin Senin Elinde" />
                            </ListItem>

                            <ListItem
                                sx={{
                                    p: 2,
                                    border: '1px solid',
                                    borderColor: 'primary.light',
                                    bgcolor: '#ffffff99',
                                    my: 1,
                                    cursor: 'pointer',
                                    transition: '0.9s',
                                    color: 'primary.main',
                                    '&:hover': {
                                        boxShadow: '0 0 30px 10px #d6ddeb'
                                    },
                                    '&:visited': {
                                        color: 'primary.main'
                                    }
                                }}
                                component={RouterLink}
                                to={routes.anonymous.whatIsOzgelecek + "#section3"}
                            >
                                <ListItemText primary="Geçmişi Silmiyoruz, Geride Bırakıyoruz" />
                            </ListItem>

                            <ListItem
                                sx={{
                                    p: 2,
                                    border: '1px solid',
                                    borderColor: 'primary.light',
                                    bgcolor: '#ffffff99',
                                    my: 1,
                                    cursor: 'pointer',
                                    transition: '0.9s',
                                    color: 'primary.main',
                                    '&:hover': {
                                        boxShadow: '0 0 30px 10px #d6ddeb'
                                    },
                                    '&:visited': {
                                        color: 'primary.main'
                                    }
                                }}
                                component={RouterLink}
                                to={routes.anonymous.whatIsOzgelecek + "#section4"}
                            >
                                <ListItemText primary="Gelecekteki Sen İle Tanış" />
                            </ListItem>
                        </List>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                    <Box component="img" src={OzgelecekHome2} height={400} />
                </Grid>
            </Grid>


            {/* Section 7 - Blog  */}
            <Grid
                container
                spacing={2}
                mt={5}
            >
                <Grid item xs={12} >
                    <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="h2" mt={2} color="primary.main">
                            En Yeni Bloglar
                        </Typography>
                        <Button
                            variant="text"
                            color="primary"
                            size="large"
                            onClick={() => navigate(routes.anonymous.blog)}
                        >
                            Tüm Blogları Gör
                        </Button>
                    </Box>
                </Grid>
                {
                    blogPosts?.data?.map((blog: any, index: any) => (
                        <Grid item xs={12} md={4} key={index} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                            <BlogCard
                                slug={blog?.slug}
                                title={blog?.title}
                                image={blog?.imageUrl}
                                readingTime={blog?.readingTime}
                                content={blog?.content}
                                date={moment(blog?.createdAt).fromNow()}
                                categories={blog?.categories}
                            />
                        </Grid>
                    ))
                }

            </Grid>

            <Dialog open={openDialog}
                onClose={() => {
                    setOpenDialog(false)
                    setProgress(0)
                }}
            >
                <DialogTitle align='center'>
                    <img src={JobHunt} alt='ilan bul' style={{ width: 200, height: 200 }} />
                </DialogTitle>
                <DialogContent>
                    <Typography textAlign={"center"}>
                        Bu içeriklere erişebilmek için giriş yapmanız gerekmektedir.
                    </Typography>

                    <Typography fontSize={12} color="text.secondary" mt={2} mb={1} textAlign={"center"}>
                        Otomatik şekilde yönlendirileceksiniz.
                    </Typography>

                    <LinearProgress variant="determinate" value={progress} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            setOpenDialog(false)
                            setProgress(0)
                        }}
                        variant='text'
                    >
                        İptal
                    </Button>

                </DialogActions>
            </Dialog>


        </Stack >
    )
}


function BlogCard(props: any) {
    const { slug, title, content, date, readingTime, image, } = props;
    const navigate = useNavigate();

    return (
        <Box
            onClick={() => navigate(routes.anonymous.blogDetail.replace(":slug", slug))}
            sx={{
                width: '100%',
                maxWidth: 340,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                boxShadow: 3,
                overflow: 'hidden',
                textAlign: 'left',
                height: '100%',
                cursor: 'pointer',
                transition: '0.3s',
                '&:hover': {
                    boxShadow: 15,
                },
            }}
        >
            <Box
                component="img"
                src={image}
                alt={title}
                sx={{ width: '100%', height: "250px", objectFit: 'cover', border: "15px solid #fff" }}
            />
            <Box p={2} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                {/* <Typography variant="overline" color="textSecondary">
                    {categories?.map((category: any, index: any) => (
                        <Chip key={index} label={category} color="primary" size="small" variant='outlined' style={{ marginRight: 5 }} />
                    ))}
                </Typography> */}
                <Typography variant="h6" component="div" gutterBottom mt={1}>
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {date}
                </Typography>
                <Typography variant="body2" component="p">
                    {new DOMParser().parseFromString(content, 'text/html').body.textContent?.substring(0, 120) + "..."}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom mt={1}>
                    Okuma Süresi: {readingTime}
                </Typography>
                <Button variant="text" sx={{ mt: 1 }} color="primary">
                    Detay
                </Button>
            </Box>


        </Box>
    );
}